import AssetSelect from "../components/AssetSelect";
import React, {useState} from "react";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners"
import { css } from "@emotion/react";
import { currencyFxManagement, getAllWalletsInfo, handleWalletAccountTransaction, getAllWalletsInfoByAsset } from "../utilityFunctions/WalletUtil";
import StepProgress from "../components/ui/StepProgress";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Colors from "../components/ui/Colors"
import Autocomplete from '@mui/material/Autocomplete';
import {wupoServerUri} from "../Api";
import NumberFormat from 'react-number-format';
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { Stepper, Step, StepLabel } from '@mui/material';
import AssetsMenu from "./AssetsMenu";
import { useTranslation } from 'react-i18next';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const assetsToFetch = {
    fromAsset: "",
    toAsset: "",
    amount: ""
};

const paymentRequest = {
    amount: "",
    sourceAsset: "", //This sould not be necessary if the backend is able to determine the asset from the source account
    destinationAsset: "", //This should be the asset that the user wants to send
    destinationWalletAccountCode: "",
    accountType: "",
    bank: "",
    bankRoutingNumber: "",
    accountName: "",
    accountNumber: "",
}


//This function will support the payment process that should include the following steps:
//1. Select the amount
//2. Select the asset
//3. Select the destination account or username
//4. Confirm the payment
//5. Send the payment
//6. Show the result
// When the user selects the asset to send, the system will show the amount in the destination asset through an api call to the backend
// The user will be able to select the destination account or username from a list of users

export default function WalletPayment(props) {

  const {loginUser} = props;

  const history = useHistory();

  const { t } = useTranslation();

  const assetFromHandler = props.location?.state?.account.asset;

  const accountFromWallet = props.location?.state?.account;

  const [loading, setLoading] = useState(false);

  const [stepNumber, setStepNumber] = useState(0);
  const [step, setStep] = useState(0);

  const [inputType, setInputType] = useState("number");

  const [eraseLabel, setEraseLabel] = useState(false);

  const [inputRender, setInputRender] = useState("");

  const [paymentAmount, setPaymentAmount] = useState("");
  const [destinationAsset, setDestinationAsset] = useState("");

  const steps = [
    {
      key: "value",
      name: t("WalletPayment.amount"),
      value: "",
    },
    {
      key: "destionationAsset",
      name: t("WalletPayment.currency"),
      value: "",
    },
    {
      key: "destination",
      name: t("WalletPayment.recipeint"),
      value: "",
    },
    {
      key: "docs",
      name: t("WalletPayment.docs"),
      value: "",
    },
    
];


  React.useEffect(() => {
      initialValues();
  }, []);

  const initialValues = async () => {

    // getWallets();

    // if (assetFromHandler) {
    //     assetsToFetch.fromAsset = assetFromHandler;
    //     paymentRequest.sourceAsset = assetFromHandler;
    //     // assetsFx("USD", 100000);
    // }
    console.log(accountFromWallet);
    if(accountFromWallet){
      paymentRequest.sourceAsset = accountFromWallet.asset;
      assetsToFetch.fromAsset = accountFromWallet.asset;
    }
  };

  const [walletsList, setWalletsList] = useState([]);

  const getWallets = async (asset) => {

    const requestAsset = {
      asset: asset,
    }

    setLoading(true);
    const wallets = await getAllWalletsInfoByAsset(requestAsset, loginUser.jwtToken);
    setLoading(false);
    if (wallets) {
        setWalletsList(
          {
            options: wallets,
            getOptionLabel: (option) => option.username,
          }
        )
    }
  };

  const assetsFx = async (destinationAsset, amount) => {
    
    assetsToFetch.toAsset = destinationAsset;
    assetsToFetch.amount = amount;

    const fxRate = await currencyFxManagement(assetsToFetch, loginUser.jwtToken);
    if (fxRate) {
        return fxRate;
    }
    else {
        return null;
    }
  };

  const nextStep = async () => {

    if(stepNumber === 1){
      console.log("Getting wallets");
      await getWallets(paymentRequest.destinationAsset);
    }

    if (stepNumber < steps.length) {
      setStepNumber(stepNumber + 1);
    }
  };

  const previousStep = () => {
    if (stepNumber > 0) {
      setStepNumber(stepNumber - 1);
    }
  };

  const paymentAmountChange = (e) => {
    
    if(e.floatValue > accountFromWallet.balance){
      swal({
        title: "Error",        
        text: "No tienes suficientes fondos",
        icon: "error",
        button: "Ok",
      }).then(() => {
        setStepNumber(0);
        deleteValues();
      });
      return;
    };

    setPaymentAmount(e.floatValue);
    paymentRequest.amount = e.floatValue;
  };

  const [fxRate, setFxRate] = useState(null);
  const [currencySelected, setCurrencySelected] = React.useState("");

  const assetChange = async (selectedAsset) => {

    const asset = selectedAsset.asset;

    setLoading(true);
    const fxRateResponse = await assetsFx(asset, paymentRequest.amount);
    setLoading(false);

    if(fxRateResponse) {
      setFxRate(fxRateResponse.rate);      
    }

    setDestinationAsset(asset);
    paymentRequest.destinationAsset = asset;
  };

  const [destinationWallet, setDestinationWallet] = useState(null);

  const onChangeAutocomplete = (event, value) => {
    if(!value){
        return;
    }

    paymentRequest.destinationWalletAccountCode = value.code;
    setDestinationWallet(value);
    
  };

  const submitPayment = async () => {
    setLoading(true);
    const response = await handleWalletAccountTransaction(paymentRequest, loginUser.jwtToken);
    setLoading(false);

    console.log(response);

    if(!response){
      swal({
        title: "Error",        
        icon: "error",
        button: "Ok",
      }).then(() => {
        setStepNumber(0);
        deleteValues();
      });
      return;
    }
    
      swal({
        title: t("WalletPayment.successfulTx"),        
        icon: "success",
        button: "Ok",
      }).then(() => {
        history.push("/wallet/acounts");
      });

  };

  const deleteValues = () => {
    setPaymentAmount("");
    setDestinationAsset("");
    setDestinationWallet("");
    setFxRate(null);

    paymentRequest.amount = "";
    paymentRequest.destinationAsset = "";
    paymentRequest.destinationWalletAccountCode = "";
    assetsToFetch.amount = "";
    assetsToFetch.toAsset = "";

  };

  const [invoice, setInvoice] = useState(true);
  const [tradeAgreement, setTradeAgreement] = useState(true);

  const [accountType, setAccountType] = useState("Bank account");
  const [bank, setBank] = useState("");
  const [bankRoutingNumber, setBankRoutingNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  
  const accountTypeChange = (e) => {
    setAccountType(e.target.value);
    paymentRequest.accountType = e.target.value;
  };

  const bankChange = (e) => {
    setBank(e.target.value);
    paymentRequest.bank = e.target.value;
  };

  const bankRoutingNumberChange = (e) => {
    setBankRoutingNumber(e.target.value);
    paymentRequest.bankRoutingNumber = e.target.value;
  };

  const accountNameChange = (e) => {
    setAccountName(e.target.value);
    paymentRequest.accountName = e.target.value;
  };

  const accountNumberChange = (e) => {
    setAccountNumber(e.target.value);
    paymentRequest.accountNumber = e.target.value;
  };

  const generateRandomKey = () => {
    const randomString = Math.random().toString(36).slice(2);
    const timestamp = Date.now().toString(36);
    return randomString + '_' + timestamp;
  };

  return (
    <Box sx={{display: "flex", width: "100%", height: "100%", backgroundColor: "#ededed"}}>
      {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
        <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: {xs: "start", sm: "center", md: "center"},
                width: "95%",
                height: "90%",
                textAlign: "center",
                marginTop: {xs: "1.3rem", sm: 0},
            }}
        >
          <Grid container sx={{marginTop: {xs: "2rem", sm: 0}}}>
            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "1rem", marginBottom: "5rem"}}>
              <Stepper activeStep={stepNumber} alternativeLabel={true} sx={{width: {xs: "100%", sm: "80%", md: "55%"}}}>
                {steps.map((step, index) => (
                  <Step key={generateRandomKey()} completed={index < stepNumber}>
                    <StepLabel key={generateRandomKey()}>
                      <Typography key={generateRandomKey()} sx={{fontSize: {xs: "0.8rem", sm: "0.8rem", md: "0.9rem"}}}>{step.name}</Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            {stepNumber === 0 &&
              <>
              <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                <NumberFormat
                  variant="standard"
                  sx={{width: {xs: "80%", sm: "60%", md: "40%"}}}
                  name={"formatNumber"}
                  customInput={TextField}
                  prefix={assetFromHandler ? `${assetFromHandler} $` : "$"}
                  type="text"
                  label= {t("WalletPayment.amountLabel")}
                  thousandSeparator={','}
                  decimalSeparator={'.'}
                  autoComplete={"off"}
                  value={paymentAmount}
                  onValueChange={(values) => {
                    paymentAmountChange(values);
                  }}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue >= 0 ? true : floatValue===undefined? true : false // Only allow numbers greater than or equal to 0 or empty string
                  }}
                /> 
              </Grid>
              <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                  <Button disabled={paymentAmount? false : true} onClick={nextStep} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                      Next
                  </Button>
              </Grid>
              </>
            }
            {stepNumber === 1 &&
              <>
              {!fxRate ?
              <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                {/* <AssetSelect
                  onChangeInfo={(e) => assetChange(e)}
                /> */}
                <AssetsMenu 
                  handleAssetSelection={assetChange} 
                  currencySelected={currencySelected}
                  setCurrencySelected={setCurrencySelected}
                />
              </Grid>
              :
              <>
              <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                {/* <AssetSelect
                  onChangeInfo={(e) => assetChange(e)}
                /> */}
                <AssetsMenu 
                  handleAssetSelection={assetChange}
                  currencySelected={currencySelected}
                  setCurrencySelected={setCurrencySelected}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1.4rem", marginBottom: "1rem"}}>
                <Typography variant="body1" sx={{fontWeight: "bold"}}>{t("WalletPayment.amountReceived")} $ {new Intl.NumberFormat('es-CO').format(fxRate * paymentRequest.amount)}</Typography>
              </Grid>
              </>
              }
              <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "end"}}>
                <Button onClick={previousStep} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem", marginRight: "2rem"}}>
                    Back
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start"}}>
                <Button disabled={fxRate? false : true} onClick={nextStep} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem", marginLeft: "2rem"}}>
                    Next
                </Button>
              </Grid>
              </>
            }
            {stepNumber === 2 &&
              <>
              <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "1rem"}}>
                <Grid container sx={{width: "60%"}}>
                  <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "1rem"}}>
                    <Autocomplete
                      {...walletsList}
                      id="destinationAccount"
                      sx={{width: {xs: "80%", sm: "80%", md: "60%"}}}

                      clearOnEscape
                      autoComplete = {true}
                      noOptionsText = {"Account not found"}
                      // sx={{ width: 600 }}
                      onChange={onChangeAutocomplete}
                      // onInputChange={onChange}
                      renderInput={(params) => (
                          <TextField {...params} label={<Typography variant="body2">{t("WalletPayment.email")}</Typography>} variant="standard" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      variant="standard"
                      sx={{width: {xs: "80%", sm: "80%", md: "60%"}, marginTop: "1rem"}}
                      label="Account Type"
                      fullWidth
                      value={accountType}
                      onChange={accountTypeChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="standard"
                      sx={{width: {xs: "80%", sm: "80%", md: "80%"}, marginTop: "1rem"}}
                      label="Bank"
                      fullWidth
                      value={bank}
                      onChange={bankChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="standard"
                      sx={{width: {xs: "80%", sm: "80%", md: "80%"}, marginTop: "1rem"}}
                      label="Bank Routing Number"
                      fullWidth
                      value={bankRoutingNumber}
                      onChange={bankRoutingNumberChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="standard"
                      sx={{width: {xs: "80%", sm: "80%", md: "80%"}, marginTop: "1rem"}}
                      label="Account Name"
                      fullWidth
                      value={accountName}
                      onChange={ accountNameChange }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="standard"
                      sx={{width: {xs: "80%", sm: "80%", md: "80%"}, marginTop: "1rem"}}
                      label="Account Number"
                      fullWidth
                      value={accountNumber}
                      onChange={ accountNumberChange }
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "end"}}>
                    <Button onClick={previousStep} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "3rem", marginRight: "2rem"}}>
                        Back
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start"}}>
                    <Button disabled={destinationWallet ? false : true} onClick={nextStep} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "3rem", marginLeft: "2rem"}}>
                        Next
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              </>
            }
            {stepNumber === 3 &&
              <>
              <Grid item xs={6} sm={6} md={6} sx={{display: "flex", alignItems: "center", justifyContent: "end"}}>
                <Checkbox
                  checked={invoice}
                  color="secondary"
                  onChange={(event) => {
                    if (!event.target.checked) {
                      setInvoice(false);
                      window.open(`${wupoServerUri.devnet}/evita_invoice.pdf`, '_blank');
                    }else{
                      setInvoice(true);
                    }
                  }}
                  sx={{cursor: "pointer", marginRight: "0.5rem"}}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} sx={{display: "flex", alignItems: "center", justifyContent: "start"}}>
                <Typography variant="body1" sx={{cursor: "pointer", marginLeft: "1.5rem"}}>{t("WalletPayment.invoice")}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} sx={{display: "flex", alignItems: "center", justifyContent: "end"}}>
                <Checkbox
                  checked={tradeAgreement}
                  color="secondary"
                  onChange={(event) => {
                    if (!event.target.checked) {
                      window.open(`${wupoServerUri.devnet}/evita-trade-agreement.pdf`, '_blank');
                      setTradeAgreement(false);
                    }else{
                      setTradeAgreement(true);
                    }
                  }}
                  sx={{cursor: "pointer", marginRight: "0.5rem"}}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} sx={{display: "flex", alignItems: "center", justifyContent: "start"}}>
                <Typography variant="body1" sx={{cursor: "pointer", marginLeft: "1.5rem"}}>{t("WalletPayment.tradeAgreement")}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "end"}}>
                <Button onClick={previousStep} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "3.5rem", marginRight: "2rem"}}>
                    Back
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start"}}>
                <Button variant="contained" onClick={nextStep} sx={{backgroundColor: Colors.primary, marginTop: "3.5rem", marginLeft: "2rem"}}>
                    Next
                </Button>
              </Grid>
              </>
            }
            {stepNumber === 4 && destinationWallet &&
              <>
              <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                <Typography variant="body2">{t("WalletPayment.recipientConfirmed")}: {destinationWallet.name} {destinationWallet.lastName}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                <Typography variant="body2">{t("WalletPayment.user")}: {destinationWallet.username}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                <Typography variant="body2">{t("WalletPayment.amountConfirmed")}: ${new Intl.NumberFormat('es-CO').format(paymentRequest.amount)}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                <Typography variant="body2">{t("WalletPayment.currencyConfirmed")}: {destinationAsset}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                <Typography variant="body2">{t("WalletPayment.rateConfirmed")}: {fxRate}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "end"}}>
                <Button onClick={previousStep} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem", marginRight: "2rem"}}>
                    Back
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start"}}>
                <Button onClick={submitPayment} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem", marginLeft: "2rem"}}>
                    Submit
                </Button>
              </Grid>
              </>
            }
          </Grid>
        </Box>
      }
    </Box>
  );
}
