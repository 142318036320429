import React from "react";
import styled from "@emotion/styled";
import Colors from "../components/ui/Colors";

const AppSelect = styled.select(() => ({
  width: "80%",
  height: "40px",
  borderColor: Colors.primary,
  borderRadius: "4px",
  backgroundColor: "white",
  outline: "none",
}));

export default function CurrencySelect(props) {
  const { onChangeInfo } = props;

  return (
    <div style={{ width: "50%", textAlign: "center" }}>
      <AppSelect onChange={onChangeInfo}>
        <option value="" hidden>
          Selecciona
        </option>
        {/* <option>WUPOS</option> */}
        {/* <option>COP</option> */}
        <option>USD</option>
        {/* <option>MXN</option> */}
        <option>EUR</option>
        <option>CEDI</option>
        {/* <option>CNY</option> */}
        {/* <option>APL</option>
        <option>GOG</option>
        <option>FCB</option>
        <option>AMZ</option>
        <option>TSL</option> */}
        {/* <option>GLD</option> */}
        {/* <option>Rappi</option>
        <option>Merqueo</option>
        <option>Laika</option>
        <option>Uber</option>
        <option>Gastronomy Market</option> */}
        {/* <option>XRP</option> */}
        {/* <option>ETH</option>
        <option>BTC</option>
        <option>IMS</option>
        <option>MIN</option>
        <option>HOY</option> */}
      </AppSelect>
    </div>
  );
}
