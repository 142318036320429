import {wupoServerUri} from '../Api';

const currencyFxManagement = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/assetsFxManagement`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const responseObject = await response.json();
      console.log(responseObject);
  
      return responseObject;
    }
  
    else {
      return null;
    }
};

const createWallet = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/createWallet`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const responseObject = await response.json();
      console.log(responseObject);
  
      return responseObject;
    }
  
    else {
      return null;
    }
};

const getUserWallet = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/wallet`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const responseObject = await response.json();
      console.log(responseObject);
  
      return responseObject;
    }
  
    else {
      return null;
    }
};

const getUserWalletWithUsd = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/walletDao`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
};

const getUserWalletAccounts = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/walletAccounts`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const responseObject = await response.json();
      console.log(responseObject);
  
      return responseObject;
    }
  
    else {
      return null;
    }
};

const getAllWalletsInfo = async(jwtToken) => {
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/allWallets`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const responseObject = await response.json();
      console.log(responseObject);
  
      return responseObject;
    }
  
    else {
      return null;
    }
};

const getAllWalletsInfoByAsset = async(requestObject, jwtToken) => {

  console.log(requestObject);
  
  const response = await fetch(`${wupoServerUri.devnet}/api/secure/allWalletsByAsset`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
};

const createWalletAccount = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/createWalletAccount`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const responseObject = await response.json();
      console.log(responseObject);
  
      return responseObject;
    }
  
    else {
      return null;
    }
};

const handleWalletAccountTransaction = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/walletTransaction`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const responseObject = await response.json();
      console.log(responseObject);
  
      return responseObject;
    }
  
    else {
      return null;
    }
};

const getWalletAccount = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/walletAccountForStatementById`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
};


export {currencyFxManagement, createWallet, getUserWallet, getUserWalletAccounts, getAllWalletsInfo, 
    createWalletAccount, handleWalletAccountTransaction, getAllWalletsInfoByAsset, getUserWalletWithUsd, getWalletAccount};