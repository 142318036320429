import {wupoServerUri} from '../Api';
import swal from "sweetalert";
import operationUnsuccesful from "../Texts/OperationResult.js";

const RippleAPI = require('ripple-lib').RippleAPI;

const api = new RippleAPI({
  server: process.env.REACT_APP_XRPL_SERVER // Public rippled server hosted by Ripple, Inc.
});  

let retryCounter = 0;

const delay = ms => new Promise(res => setTimeout(res, ms));

async function xrpPayment (ledgerPayment, paymentValue, destinationAddress, secret){
  await delay(500);
    return new Promise((resolve, reject)=>{
            const payment={...ledgerPayment}
            
            console.log(payment);

            api.on('error', (errorCode, errorMessage) => {
                console.log(errorCode + ': ' + errorMessage);
            });
            
            api.connect().then(() => {
                // console.log("JSON payment in function: " + JSON.stringify(payment)); // DEBUG PRINTING
                api.preparePayment(payment.source.address, payment)
                .then(prepare => {
                    const {signedTransaction, id} = api.sign(prepare.txJSON, secret)
                    
                    api.submit(signedTransaction).then(result => {
                            // console.log("XRPL payment result: ") // DEBUG PRINTING
                            console.log(result); // DEBUG PRINTING
                            // console.log("result code: " + result.resultCode)  // DEBUG PRINTING
                            if(result.resultCode==="tesSUCCESS"){
                                retryCounter = 0;
                                resolve("payment ok")
                            }else if(result.resultCode==="tecPATH_PARTIAL"){
                                console.log("payment failed");
                                resolve("tecPATH_PARTIAL");
                            }else {
                                console.log("payment failed");
                                resolve("payment failed");
                            }               
                        }); 
                                                    
                }).catch(errorPrepare => {
                    
                    retryCounter++;
                    if(retryCounter <= 20){
                      xrpPayment(ledgerPayment, paymentValue, destinationAddress, secret)
                      .then(() => {
                        retryCounter = 0;
                        resolve("payment ok");
                      });
                    }else{
                      retryCounter = 0;
                      console.log(errorPrepare);
                      swal({
                        title: "NO PUDIMOS HACER TU PAGO",
                        text: operationUnsuccesful,
                        icon: "error",
                        button: "Entiendo",
                      });
                    }
                });
            }).catch(error =>{
              retryCounter++;
              if(retryCounter <= 20){
                xrpPayment(ledgerPayment, paymentValue, destinationAddress, secret)
                .then(() => {
                  retryCounter = 0;
                  resolve("payment ok");
                });
              }else{
                retryCounter = 0;
                console.log(error);
                swal({
                  title: "NO PUDIMOS HACER TU PAGO",
                  text: operationUnsuccesful,
                  icon: "error",
                  button: "Entiendo",
                });
              }
              
            })
    })
        
}


let batchRetryCounter = 0;
const batchXrpPayment = async (ledgerPayment, secret) => {
  // await delay(1000);
    // return new Promise((resolve, reject)=>{
  const payment={...ledgerPayment}
            
  console.log("Batch Payment: " + JSON.stringify(payment))

  api.on('error', (errorCode, errorMessage) => {
      console.log(errorCode + ': ' + errorMessage);
  });

    try{  
      await api.connect();
                    // console.log("JSON payment in function: " + JSON.stringify(payment)); // DEBUG PRINTING
      const prepare =  await api.preparePayment(payment.source.address, payment)
                    
      const {signedTransaction, id} = api.sign(prepare.txJSON, secret)
                        
      const result = await api.submit(signedTransaction);
                                // console.log("XRPL payment result: ") // DEBUG PRINTING
      console.log(result); // DEBUG PRINTING
      // console.log("result code: " + result.resultCode)  // DEBUG PRINTING
      if(result.resultCode==="tesSUCCESS"){
          retryCounter = 0;
          return new Promise((resolve, reject)=>{
            resolve("payment ok")
          });
          
      }else if(result.resultCode==="tecPATH_PARTIAL"){

          console.log("payment failed");
          return new Promise((resolve, reject)=>{
            resolve("tecPATH_PARTIAL");
          });
          
      }else {
          console.log("payment failed");
          return new Promise((resolve, reject)=>{
            resolve("payment failed");
          });
          
      }           
    }
    catch(error){
      console.log(error);
      batchRetryCounter++;

      if(batchRetryCounter <= 30){
        batchXrpPaymentRetry(ledgerPayment, secret);
      }
    }                                
   
        
}



const batchXrpPaymentRetry = async (ledgerPayment, secret) => {
  await delay(500);
  batchXrpPayment(ledgerPayment, secret);
};

function xrplOrder(wupoXrplAddress, wupoXrplSecret, destinationAsset, sourceAsset, sellValue, price){
    return new Promise((resolve, reject)=>{
        try{      
          api.connect().then(() => {

            const address = wupoXrplAddress;
            const order = {
              "direction": "sell",
              "quantity": {
                "currency": destinationAsset,
                "counterparty": wupoXrplAddress,
                "value": sellValue
              },
              "totalPrice": {
                "currency": sourceAsset,
                "counterparty": wupoXrplAddress,
                "value": price
              },
              "passive": false,
              //"orderToReplace": 11427442
            };
            
            const secret = wupoXrplSecret;  
            console.log("order: " + JSON.stringify(order)) // DEBUG PRINTING
            // console.log("secret: " + secret)
            return api.prepareOrder(address, order).then(prepare => {
                // console.log("prepare: " + JSON.stringify(prepare))
              const {signedTransaction, id} = api.sign(prepare.txJSON, secret);
                // console.log("sign: " + signedTransaction)
                return api.submit(signedTransaction)
                .then(result => {
                    console.log(result); // DEBUG PRINTING
                    if(result.resultCode === "tesSUCCESS"){
                      resolve(result);
                    }else{
                      reject();
                    }
                });
            
            }).catch(errorPrepare => {
              console.log(errorPrepare);
              reject(errorPrepare);
            });
            
          }).catch(error => {

            retryCounter++;
              if(retryCounter <= 20){
                xrplOrder(wupoXrplAddress, wupoXrplSecret, destinationAsset, sourceAsset, sellValue, price);
              }else{
                retryCounter = 0;
                console.log(error);
                swal({
                  title: "NO PUDIMOS HACER TU PAGO",
                  text: operationUnsuccesful,
                  icon: "error",
                  button: "Entiendo",
                });
              }

          });

          
        }catch(error){
          console.log(error)
        }
        //   .then(() => {
        //     return api.disconnect();
        //   })
          // .then (() =>{
          //   console.log('done and disconnected.');
          // }).catch(console.error);
    });    
}

const token="";
function placeOrder(orderObject, jwtToken){
  // console.log("Place order object: " + JSON.stringify(orderObject)) // DEBUG PRINTING
  fetch(`${wupoServerUri.devnet}/api/secure/changePosition`, {

    method: 'POST',
    body: JSON.stringify(orderObject),
    headers: {
      'Authorization': `Bearer ${jwtToken}`, 
      'Content-Type': 'application/json'                     
    }
    
  }).then(function (response){ 
    // console.log(response.status) // DEBUG PRINTING
    return response.json();
    // return response.json();

  }).then(function(body){
      console.log(JSON.stringify(body));
      
  }).catch(function(error){
      console.log("Error msg: "+ error);
  });
}

function currenciesOrder(exchangeOrder, jwtToken){
  console.log(exchangeOrder) // DEBUG PRINTING
  fetch(`${wupoServerUri.devnet}/api/secure/currenciesorders`, {

    method: 'POST',
    body: JSON.stringify(exchangeOrder),
    headers: {
      'Authorization': `Bearer ${jwtToken}`, 
      'Content-Type': 'application/json'                     
    }
    
  }).then(function (response){ 
    // console.log(response.status); // DEBUG PRINTING
    return response.text();

  }).then(function(body){
      console.log(`Placed currency order ID: ${JSON.stringify(body)}`)
      
  }).catch(function(error){
      console.log("Error msg: "+ error);
  });
}

function prepareStatement(prepareStatementObject, jwtToken){
  console.log(prepareStatementObject); // DEBUG PRINTING
  
  return(
    fetch(`${wupoServerUri.devnet}/api/secure/preparestatements`, {
        method: "POST",
        body: JSON.stringify(prepareStatementObject),
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
    })
    .then((response) => {
        return response.json(); 
    })
    .then((data) => {
        console.log(data); // DEBUG PRINTING
        return data;
    })
    .catch((error) => {
        console.log("Error: " + error);
    })
  )
}

function createTrustline(clientXrplAddress, trustline, clientXrplToken){
  console.log(trustline);
  // console.log(clientXrplAddress); //LOANS DEBUG PRINTING
  // console.log(clientXrplToken); //LOANS DEBUG PRINTING
  return new Promise((resolve, reject)=>{

    try{

      api.connect().then(() => {
        api
          .prepareTrustline(clientXrplAddress, trustline)
          .then((prepare) => {
            const { signedTransaction, id } = api.sign(
              prepare.txJSON,
              clientXrplToken
            );
    
    
            api.submit(signedTransaction).then((result) => {
              console.log(result); // DEBUG PRINTING
              // console.log(result.resultCode)
              if(result.resultCode === "tesSUCCESS"){
                resolve(result);
              }else{
                reject();
              }
              
            })
            .catch((error) =>{
              console.log("submit catch");
              console.log(error);
            });
          })
          .catch((errorPrepare) => {
            console.log("prepare catch");
            console.log(errorPrepare);
            reject()
          });
      }).catch(error => {

        retryCounter++;
              if(retryCounter <= 20){
                createTrustline(clientXrplAddress, trustline, clientXrplToken);
              }else{
                retryCounter = 0;
                console.log(error);
                swal({
                  title: "NO PUDIMOS HACER TU PAGO",
                  text: operationUnsuccesful,
                  icon: "error",
                  button: "Entiendo",
                });
              }

      })
      .catch((error) =>{
        console.log("first catch")
        console.log(error);
      });

    }catch(error){
      console.log("catch")
      console.log(error)
    }
    

  });

}

const batchXrpPaymentPrep = (rewards, jwtToken) => {
  
  console.log(rewards);

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/batchRewards`, {
        method: "POST",
        body: JSON.stringify(rewards),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
    })
    .then((response) => {
        console.log(response.status);
        if(response.status === 200){
            return response.json();
        }
        else{
            return "Not found";
        }
    })
    .then((rewardsPayment) => {
        console.log(rewardsPayment); // DEBUG PRINTING
        
        return rewardsPayment;
    })
    .catch((error) => {
        console.log("Error: " + error);
    })
  )

}

/* ************Creates New Account in the XRPL************** */
function createXrplAccount(){

  //Options Object to create the XRPL Adress
  const adressOptions = {
    test: true,
    includeClassicAddress: true,
  }
  
  return(
 
    api.connect().then(() => {

      // Generate Address and Secret
      return (api.generateXAddress(adressOptions));

    }).catch((err) => {
      console.log(err);
    })

  );

}

let retryCountPersistAccount = 0;
function persistAccount(accountObject, jwtToken){
  // console.log(prepareStatementObject); // DEBUG PRINTING
  
  return(
    fetch(`${wupoServerUri.devnet}/api/secure/ledger/accounts`, {
        method: "POST",
        body: JSON.stringify(accountObject),
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
    })
    .then((response) => {
        return response.json(); 
    })
    .then((data) => {
        console.log(data); // DEBUG PRINTING
        return data;
    })
    .catch((error) => {
        console.log("Error: " + error);
        // retryCountPersistAccount++;
        //     if(retryCountPersistAccount<=10){
        //       persistAccount();
        //     }
        //     else{
        //       return "error";
        //     }
    })
)

}


export {persistAccount, xrpPayment, xrplOrder, placeOrder, currenciesOrder, 
  prepareStatement, createTrustline, createXrplAccount, batchXrpPayment, batchXrpPaymentPrep }