import React from "react";
import { Router, Route, Switch } from "react-router-dom";

import NavBar from "./layout/NavBar";
import Container from "./layout/Container";

import {
  ChooseEscrowAction,
  ChooseTx,
  CreateAccount,
  Funding,
  HandleAccount,
  HandleEscrowExecution,
  LoggedTransfer,
  WelcomeCardAccount,
  Statement,
  Credit,
  HandleCredit,
  StoresMap,
  Refinancing,
} from "./pages/bill";

import {
  HandleUser,
  FundingWupoPayment,
  WelcomeCardTx,
} from "./pages/transfer";

import {
  AdminHandler,
  OperationalHandler,
  ProfitHandler,
  ProfitViewer,
  XrpAdminHandler,
  AdminRol,
  AdminSettings,
  LoanSettings,
  ClientRol,
  AdminKpi,
  KpiDefaultLoans,
  LoyaltyUpload,
  CreateProducts,
  BatchSettlement,
  ManualPayments,
  ManualPaymentDetail,
  DocsValidation,
  CattlesSearch,
  CattleDetail,
  MarketplaceClient,
  MarketClientData,
  ClientPurchases,
  ChangeCattleStatus,
  CSVQuerys,
} from "./pages/admin";

import {
  Activity,
  AMLForm,
  ContactLocation,
  IDInformation
} from "./pages/AML-Information"

import {
  HanldeProfile,
  QREnablingFactor,
  AdminChoose,
  RootProfile,
} from "./pages/auth"

import {
  RootChange,
  RootCreate,
  RootApi,
} from "./pages/root"

import {
  OperatorInfo,
  OperatorRole,
  OperatorLoans,
  OperatorStatements,
} from "./pages/operator"

import {
  LoyaltyRewards
} from "./pages/rewards"

import {
  Oportunities,
  NewDealsDefinitions,
  DealsDefinitionsList,
  ChangeDealsDefinitions,
  UploadDemoInfo,
  DealsDetail,
  ClosedDeals,
  CreateDeal,
  ClientsSearch,
  ClientDetail,
  SectorsList
} from "./pages/crm"

import {
  LogisticsBoard,
  UploadDeliveries,
  NewDelivery,
  StatusDelivery,
  PqrDelivary,
  DeliveryDetail,
  NewProblem,
  DeliverySearch,
} from "./pages/last-mile"

import {
  ProductsMarket,
  ProductDetail,
  ClientPortfolio,
  CreateBankAccount,
  ProductsHistory,
} from "./pages/marketplace"

import {
  CreateCreditCard,
  CreditCardMount, 
  HandleCreditCard,
  CreditCardStatement,
  PurchaseSimulator,
  QrGenerator,
  ClientDocsReview,
  ClientDocsUpload,
  WalletPayment,
  HandleWallet,
  WalletAccountStatement,
} from "./opencard"

import {
  UploadLoanInfo,
} from "./analytics"

import history from "./HistoryExport"

const AuthedRoutes = (mainProps) => {

// console.log(mainProps.navBarEnabled) //DEBUG
  return (
    <Router history={history}>
    {mainProps.navBarEnabled ? <NavBar {...mainProps} /> : "" }
      <Container>
        <Switch>
        
          {/* <Route
            exact
            path="/"
            render={(props) =>
              mainProps.loginUser.profileCompleted ?
              React.createElement(WelcomeCardAccount, {
                ...props,
                ...mainProps,
              })
              :
              React.createElement(AMLForm, {
                ...props,
                ...mainProps,
              })
            }
          /> */}
          {/* <Route exact path="/" component={Home} /> */}
          <Route
            exact
            path="/"
            render={(props) =>
              React.createElement(WelcomeCardAccount, {
                ...props,
                ...mainProps,
              })
            }
          />
          <Route
            exact
            strict
            path="/account"
            render={(props) =>
              React.createElement(WelcomeCardAccount, { ...props, ...mainProps })}
          />

          <Route
            exact
            strict
            path="/totpcode"
            render={(props) =>
              React.createElement(QREnablingFactor, { ...props, ...mainProps })}
          />

          <Route
            exact
            strict
            path="/storesmap"
            render={(props) =>
              React.createElement(StoresMap, { ...props, ...mainProps })}
          />

          <Route
            exact
            strict
            path="/handle_profile"
            render={(props) =>
              React.createElement(HanldeProfile, { ...props, ...mainProps })}
          />

          <Route 
            exact 
            path="/account/generalinfo" 
            render={(props) => 
            React.createElement(AMLForm, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/account/idinfo" 
            render={(props) => 
            React.createElement(IDInformation, { ...props, ...mainProps })} />
          <Route 
            exact 
            path="/account/contactinfo" 
            render={(props) => 
            React.createElement(ContactLocation, { ...props, ...mainProps })} />
          <Route 
            exact 
            path="/account/activityinfo" 
            render={(props) => 
            React.createElement(Activity, { ...props, ...mainProps })} />
          <Route
            exact
            path="/account/create_account"
            render={(props) =>
              React.createElement(CreateAccount, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/account/create_credit"
            render={(props) =>
              React.createElement(Credit, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/credit_newterms"
            render={(props) =>
              React.createElement(Refinancing, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/account/handle_account"
            render={(props) =>
              React.createElement(HandleAccount, { ...props, ...mainProps })
            }
          />
           <Route
            exact
            path="/commercial/deals"
            render={(props) =>
              React.createElement(Oportunities, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/commercial/newdealsdef"
            render={(props) =>
              React.createElement(NewDealsDefinitions, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/commercial/nreviewdealsdef"
            render={(props) =>
              React.createElement(DealsDefinitionsList, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/commercial/changedealsdef"
            render={(props) =>
              React.createElement(ChangeDealsDefinitions, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/commercial/dealdetail"
            render={(props) =>
              React.createElement(DealsDetail, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/commercial/closedDeals"
            render={(props) =>
              React.createElement(ClosedDeals, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/commercial/createDeals"
            render={(props) =>
              React.createElement(CreateDeal, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/lastmile/board"
            render={(props) =>
              React.createElement(LogisticsBoard, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/lastmile/batch"
            render={(props) =>
              React.createElement(UploadDeliveries, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/lastmile/newOrder"
            render={(props) =>
              React.createElement(NewDelivery, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/lastmile/newStatus"
            render={(props) =>
              React.createElement(StatusDelivery, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/lastmile/newPqr"
            render={(props) =>
              React.createElement(PqrDelivary, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/lastmile/orderDetail"
            render={(props) =>
              React.createElement(DeliveryDetail, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/lastmile/newDelay"
            render={(props) =>
              React.createElement(NewProblem, { ...props, ...mainProps })
            }
          />
           <Route
            exact
            path="/lastmile/orderSearch"
            render={(props) =>
              React.createElement(DeliverySearch, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/commercial/clientSearch"
            render={(props) =>
              React.createElement(ClientsSearch, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/commercial/sectorslist"
            render={(props) =>
              React.createElement(SectorsList, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/commercial/clientDetail"
            render={(props) =>
              React.createElement(ClientDetail, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/commercial/demoupload"
            render={(props) =>
              React.createElement(UploadDemoInfo, { ...props, ...mainProps })
            }
          />
           <Route
            exact
            path="/available-rewards"
            render={(props) =>
              React.createElement(LoyaltyRewards, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/credit/handle_credit"
            render={(props) =>
              React.createElement(HandleCredit, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/account/handle_account/funding/:asset"
            render={(props) =>
              React.createElement(Funding, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/account/statement"
            render={(props) =>
              React.createElement(Statement, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/account/handle_account/choosetx/:asset"
            render={(props) =>
              React.createElement(ChooseTx, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/account/handle_account/transer/:asset"
            render={(props) =>
              React.createElement(LoggedTransfer, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/account/handle_account/escrow/:asset"
            render={(props) =>
              React.createElement(ChooseEscrowAction, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/account/handle_account/escrow/excution/:asset"
            render={(props) =>
              React.createElement(HandleEscrowExecution, { ...props, ...mainProps })
            }
          />
          {/* Transfer */}
          <Route
            exact
            path="/transference"
            render={(props) =>
              React.createElement(WelcomeCardTx, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/transference/user"
            render={(props) =>
              React.createElement(HandleUser, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/transference/payment"
            render={(props) =>
              React.createElement(FundingWupoPayment, {
                ...props,
                ...mainProps,
              })
            }
          />
          {/* ROOT Role */}
          <Route
            exact
            path="/root-profile"
            render={(props) =>
              React.createElement(RootProfile, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/root-change"
            render={(props) =>
              React.createElement(RootChange, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/root-create"
            render={(props) =>
              React.createElement(RootCreate, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/root-api"
            render={(props) =>
              React.createElement(RootApi, { ...props, ...mainProps })
            }
          />

          {/* Client Role */}
          <Route
            exact
            path="/client-profile"
            render={(props) =>
              React.createElement(ClientRol, { ...props, ...mainProps })
            }
          />


          {/* Admin */}
          <Route
            exact
            path="/admin-choose"
            render={(props) =>
              React.createElement(AdminChoose, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/admin-rol"
            render={(props) =>
              React.createElement(AdminRol, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/admin-settings"
            render={(props) =>
              React.createElement(AdminSettings, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/admin-kpi"
            render={(props) =>
              React.createElement(AdminKpi, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/admin-kpi-loans"
            render={(props) =>
              React.createElement(KpiDefaultLoans, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/admin-batch-tokens"
            render={(props) =>
              React.createElement(LoyaltyUpload, { ...props, ...mainProps })
            }
          />

          <Route
            exact
            path="/portfolio/create-products"
            render={(props) =>
              React.createElement(CreateProducts, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/portfolio/cattle-settlement"
            render={(props) =>
              React.createElement(BatchSettlement, { ...props, ...mainProps })
            }
          />

          {/* Operator Role */}
          <Route
            exact
            path="/operator-role"
            render={(props) =>
              React.createElement(OperatorRole, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/operator-info"
            render={(props) =>
              React.createElement(OperatorInfo, { ...props, ...mainProps })
            }
          />
           <Route
            exact
            path="/operator-loans"
            render={(props) =>
              React.createElement(OperatorLoans, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/operator-statements"
            render={(props) =>
              React.createElement(OperatorStatements, { ...props, ...mainProps })
            }
          />

          <Route
            exact
            path="/loans-settings"
            render={(props) =>
              React.createElement(LoanSettings, { ...props, ...mainProps })
            }
          />

          <Route
            exact
            path="/admin"
            render={(props) =>
              React.createElement(AdminHandler, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/admin/xrp"
            render={(props) =>
              React.createElement(XrpAdminHandler, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/admin/connect/operational"
            render={(props) =>
              React.createElement(OperationalHandler, {
                ...props,
                ...mainProps,
              })
            }
          />
          <Route
            exact
            path="/admin/connect/profit"
            render={(props) =>
              React.createElement(ProfitHandler, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/admin/connect/profit/view"
            render={(props) =>
              React.createElement(ProfitViewer, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/selling/marketplace"
            render={(props) =>
              React.createElement(ProductsMarket, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/selling/marketplace/operations"
            render={(props) =>
              React.createElement(ProductsHistory, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/selling/productDetail"
            render={(props) =>
              React.createElement(ProductDetail, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/selling/client-portfolio"
            render={(props) =>
              React.createElement(ClientPortfolio, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/info/bank-account"
            render={(props) =>
              React.createElement(CreateBankAccount, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/settlement/manual-payments"
            render={(props) =>
              React.createElement(ManualPayments, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/settlement/payments-detail"
            render={(props) =>
              React.createElement(ManualPaymentDetail, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/info/validation"
            render={(props) =>
              React.createElement(DocsValidation, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/info/purchases"
            render={(props) =>
              React.createElement(CattlesSearch, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/info/purchase-detail"
            render={(props) =>
              React.createElement(CattleDetail, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/info/client"
            render={(props) =>
              React.createElement(MarketplaceClient, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/info/review-data"
            render={(props) =>
              React.createElement(MarketClientData, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/info/review-purchases"
            render={(props) =>
              React.createElement(ClientPurchases, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/admin/change-status"
            render={(props) =>
              React.createElement(ChangeCattleStatus, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/admin/market-qrys"
            render={(props) =>
              React.createElement(CSVQuerys, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/creditCard/offer"
            render={(props) =>
              React.createElement(CreateCreditCard, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/creditCard/mount"
            render={(props) =>
              React.createElement(CreditCardMount, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/creditCard/management"
            render={(props) =>
              React.createElement(HandleCreditCard, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/creditCard/statement"
            render={(props) =>
              React.createElement(CreditCardStatement, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/creditCard/purchase-simulator"
            render={(props) =>
              React.createElement(PurchaseSimulator, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/creditCard/qr-payment"
            render={(props) =>
              React.createElement(QrGenerator, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/loans/docs-review"
            render={(props) =>
              React.createElement(ClientDocsReview, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/loans/docs-upload"
            render={(props) =>
              React.createElement(ClientDocsUpload, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/wallet/acounts"
            render={(props) =>
              React.createElement(HandleWallet, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/wallet/acounts/statement"
            render={(props) =>
              React.createElement(WalletAccountStatement, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/wallet/payments"
            render={(props) =>
              React.createElement(WalletPayment, { ...props, ...mainProps })
            }
          />
          <Route
            exact
            path="/analytics-loans/upload-info"
            render={(props) =>
              React.createElement(UploadLoanInfo, { ...props, ...mainProps })
            }
          />

          {/* {mainProps.userData?.profileCompleted ? <Redirect to="/" /> : <Redirect to="/account/generalinfo" />} */}
          {/* {mainProps.userData.profileCompleted ? <Redirect to="/" /> : <Redirect to="/account/generalinfo" />} */}
        </Switch>
      </Container>
    </Router>
  );
}

export default AuthedRoutes;
