import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Colors from "../components/ui/Colors";
import { Button, Typography, DialogContent } from "@mui/material";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
// import CardImg from "../img/creditcards/card2.png";
import CardImg from "../img/creditcards/cardClean.png";
// import NumberFormat from "react-number-format";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {getProductInfo, purchaseAuth, purchaseOtp, purchaseOtpVerification} from "../utilityFunctions/OpenCardUtil";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Bancolombia from "../img/creditcards/logo-bancolombia.png";
import Seal from "../img/creditcards/success.png";
import {QrReader} from 'react-qr-reader';
import CountDownTimer from './CountDownTimer';
import { useTranslation } from 'react-i18next';
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptionsHour = {
    // weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: 'numeric',
    minute: 'numeric',
};

const termArray = Array.from({ length: 48 }, (_, index) => index + 1);

const productRequest = {
    username: "",
};

const purchaseObject = {
    username: "",
    internalCardCode: "",
    expDate: "",
    transactionAmount: "",
    extendedPaymentCode: "",
};

const constraints = {
    facingMode: "environment",
};

const otpVerifyObject = {
    id: "",
    otp: "",
};

const EmvQrCode = true;

export default function PurchaseSimulator(props) {

    const{loginUser} = props;
    
    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const { t } = useTranslation();

    React.useEffect(()=> {
        fetchProducts();
    }, []);

    const[cards, setCards] = React.useState("");

    const fetchProducts = async () => {
        setLoading(true);

        productRequest.username = loginUser.accountId;
        const productsResponse = await getProductInfo(productRequest, loginUser.jwtToken);
        console.log(productsResponse);

        setCards(
            {
                options: productsResponse.listGeneralProduct,
                getOptionLabel: (option) => option.cardNumber,
            }
        );
        setLoading(false);
    };

    
    const[purchaseValue, setPurchaseValue] = React.useState("");
    const [merchant, setMerchant] = React.useState("");
    const [disableBuy, setDisableBuy] = React.useState(true);

    const onChangeAutocomplete = (event, value) => {
        console.log(value);
        if(value){
            purchaseObject.internalCardCode = value.internalCode;
            purchaseObject.username = loginUser.accountId;
            purchaseObject.expDate = value.importantDate.expirationDate.substring(2, 6);

            setDisableBuy(false);
        }

        else{
            setDisableBuy(true); 
        }
       
    };

    const [otpBox, setOtpBox] = React.useState(false);
    
    const handleTimerEnd = () => {
        setOtpBox(false);
    };

    const otpChange = (e) => {
        if(e.target.value && e.target.value.length === 6){
            otpVerifyObject.otp=e.target.value;
            setOtpDisabled(false);
        }
        else{
            setOtpDisabled(true);
        }
    };

    const[otpDisabled, setOtpDisabled] = React.useState(true);

    const handleOtp = async () => {

        const otpRequest = {
            username: loginUser.accountId,
        }

        setLoading(true);
        const otpResponse = await purchaseOtp(otpRequest, loginUser.jwtToken);
        setLoading(false);

        if(!otpResponse){
            swal({
                title: "Error",
                text: t("PurchaseSimulator.unsuccessfulPaymentText"),
                icon: "error",
                button: "ok",
            });
            return;
        };

        otpVerifyObject.id=otpResponse.id;

        setOtpBox(true);

    };

    const otpValidation = async () => {
        setOtpBox(false);
        setLoading(true);
        const otpResponse = await purchaseOtpVerification(otpVerifyObject, loginUser.jwtToken);
        setLoading(false);

        if(!otpResponse){
            swal({
                title: "Error",
                text: t("PurchaseSimulator.unsuccessfulPaymentText"),
                icon: "error",
                button: "ok",
            });
            return;
        };

        buy();
    };

    const buy = async () => {
        setLoading(true);

        const purchaseResponse = await purchaseAuth(purchaseObject, loginUser.jwtToken);
        console.log(purchaseResponse);
        
        setLoading(false);
        if(!purchaseResponse){
            swal({
                title: "Error",
                text: t("PurchaseSimulator.unsuccessfulPaymentText"),
                icon: "error",
                button: "ok",
            });
            return;
        };

        swal({
            title: t("PurchaseSimulator.successfulPayment"),
            icon: "success",
            button: "ok",
        }).then(()=> {
            renderPaymentSeal(purchaseResponse);

            // history.push("/creditCard/mount");
        });

    };

    const[term, setTerm] = React.useState(1);

    const termChange = (e) => {
        setTerm(e.target.value);
        purchaseObject.extendedPaymentCode = e.target.value.toString();
    };

    const [qrScanner, setQrScanner] = React.useState(false);

    const openQrScanner = () => {
        setQrScanner(true);
        setDisableBuy(true);
        setTerm(1);
    };

    const closeQrScanner = () => {
        setQrScanner(false);
    };

    const handleScan = (data) => {

        if (data) {
            console.log(data.text)


            if(EmvQrCode===true){
                
                const txLengthStartIndex = 8; //fixed value where the transaction value start
                const txLengthEndIndex = 10;

                const txLength = parseInt(data.text.substring(txLengthStartIndex, txLengthEndIndex));

                console.log(txLength);

                const txValue = data.text.substring(txLengthEndIndex, txLengthEndIndex+txLength);

                console.log(txValue);

                const nameLengthStartIndex = txLengthEndIndex+1+txLength+1;
                const nameLengthEndIndex = nameLengthStartIndex +2;

                const nameLength = parseInt(data.text.substring(nameLengthStartIndex, nameLengthEndIndex));

                console.log(nameLength);

                console.log(nameLengthEndIndex);
                
                const merchantName = data.text.substring(nameLengthEndIndex, nameLengthEndIndex+nameLength);

                console.log(merchantName);

                purchaseObject.transactionAmount=txValue;
                
                if(txValue){
                    setPurchaseValue(txValue);
                }
                if(merchantName){
                    setMerchant(merchantName);
                }
                
                return;
            }

            const jsonData = JSON.parse(data.text);

            if(jsonData.value){
                console.log(jsonData.value)
                const inputValue = jsonData.value * 100;
                purchaseObject.transactionAmount = inputValue.toString().padStart(16, "0");
                setPurchaseValue(jsonData.value);
            }

            if(jsonData.merchant){
                setMerchant(jsonData.merchant);
            }
        
        };
    };

    React.useEffect(() => {
        closeQrScanner();
    }, [purchaseValue]);
    
    const handleError = (error) => {
    console.error('Error reading QR code:', error);
    // Handle error as needed
    };

    const[paymentSeal, setPaymentSeal] = React.useState("");

    const renderPaymentSeal = (payment) => {
        
        setPaymentSeal(
            <List sx={{width: "100%",}}>
                <Grid container spacing={2}>
                    <ListItem disablePadding sx={{marginBottom: "0.8rem"}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                            <Typography variant="body2" sx={{color: "gray", fontSize: "0.8rem"}}>{`${new Intl.DateTimeFormat('es-CO', dateOptionsHour).format(new Date(Date.now()))}`}</Typography>
                        </Grid>
                    </ListItem>
                    <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                            <Typography variant="h6" sx={{color: "green"}}>{`$ ${new Intl.NumberFormat('es-CO').format(purchaseObject.transactionAmount * (1/100))}`}</Typography>
                        </Grid>
                    </ListItem>
                    <ListItem disablePadding sx={{marginBottom: "0"}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                            <Typography variant="body2" sx={{color: "gray", fontSize: "0.7rem"}}>{`${t("PurchaseSimulator.reference")}:`}</Typography>
                        </Grid>
                    </ListItem>
                    <ListItem disablePadding sx={{marginBottom: "1rem"}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                            <Typography variant="body1" sx={{color: "black"}}>{`${payment.authNum}`}</Typography>
                        </Grid>
                    </ListItem>
                    <ListItem disablePadding sx={{marginBottom: "0"}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                            <Typography variant="body2" sx={{color: "gray", fontSize: "0.7rem"}}>{`${t("PurchaseSimulator.merchant")}:`}</Typography>
                        </Grid>
                    </ListItem>
                    <ListItem disablePadding sx={{marginBottom: "1rem"}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                            <Typography variant="body1" sx={{color: "black"}}>{merchant}</Typography>
                        </Grid>
                    </ListItem>
                </Grid>
            </List>
        );

        setPaymentDone(true);
    };

    const[paymentDone, setPaymentDone] = React.useState(false);

    const closePaymentDone = () => {
        setPaymentDone(false);
        history.push("/creditCard/mount");
    };


    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center",  width: "100%", height: "100%", backgroundColor: "#ededed",}}>
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: {xs: "start", sm: "center", md: "center"},
                width: "90%",
                height: "100%",
                textAlign: "center",
            }}
            >
            <>
                
                <Grid container spacing={2} sx={{display: "flex", justifyContent: {xs: "center", sm: "center"}, alignItems: {xs: "start", sm: "center", md: "center"}, width: "100%", marginLeft: "1%", marginTop: {xs: "10%", sm: "0%"}}}>
                    <Card sx={{maxWidth: "400px", minWidth: {xs: "90%", sm: "90%", md: "300px", borderRadius: "15px"}}}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", width: "85%", position: "relative"}}>
                                <CardMedia
                                    component="img"
                                    // image={CardImg}
                                    image="/images/creditcards/card_clean.png"
                                    alt="Card"
                                    sx={{width: "95%"}}
                                />
                            </Grid>
                            {merchant &&
                                <>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginTop: "1.3rem", marginLeft: "10%"}}>
                                    <Typography sx={{color: "gray", fontSize: "0.6rem"}}>{`${t("PurchaseSimulator.merchant")}:`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography variant="body1">{`${merchant}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{marginBottom: "1rem", marginLeft: "10%"}}>
                                    <Divider sx={{width: "90%"}} />
                                </Grid>
                                </>
                            }
                            {purchaseValue &&
                                <>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography sx={{color: "gray", fontSize: "0.6rem"}}>{t("PurchaseSimulator.purchaseValue")}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography variant="body2">{`$ ${new Intl.NumberFormat('es-CO').format(purchaseValue / 100)}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{marginLeft: "10%"}}>
                                    <Divider sx={{width: "90%"}} />
                                </Grid>
                                </>
                            }
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                                <TextField
                                    sx={{textAlign: "start", width: "80%"}}
                                    variant="standard"
                                    // fullWidth={true}
                                    id="term"
                                    name="term"
                                    select
                                    label={<Typography variant="body2">{t("PurchaseSimulator.installments")}</Typography>}
                                    value={term}
                                    onChange={(e)=>termChange(e)}
                                    autoComplete="off"
                                >
                                    {termArray.map((typeEl) => (
                                        <MenuItem key={typeEl} value={typeEl}>
                                            {typeEl}
                                        </MenuItem>
                                    ))}
                                </TextField> 
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0.05rem", marginBottom: "0.2rem"}}>
                                <Autocomplete
                                    {...cards}
                                    sx={{width: "80%"}}
                                    id="cards"
                                    clearOnEscape
                                    autoComplete = {true}
                                    noOptionsText = {"Tarjeta no existe"}
                                    // sx={{ width: 600 }}
                                    onChange={onChangeAutocomplete}
                                    // onInputChange={onChange}
                                    renderInput={(params) => (
                                        <TextField {...params} label={<Typography variant="body2">{t("PurchaseSimulator.cardSelection")}</Typography>} variant="standard" />
                                    )}
                                /> 
                            </Grid>
                            {!purchaseValue &&
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "3rem", marginBottom: "1rem"}}>
                                    <Button onClick={openQrScanner} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                        {t("PurchaseSimulator.scan")}
                                    </Button>
                                </Grid>
                            }
                            {purchaseValue &&
                                <>
                                <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center", marginTop: "1.8rem", marginBottom: "1rem"}}>
                                    <Button disabled={disableBuy} onClick={handleOtp} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                        {t("PurchaseSimulator.buy")}
                                    </Button>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center", marginTop: "1.8rem", marginBottom: "1rem"}}>
                                    <Button onClick={openQrScanner} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                        {t("PurchaseSimulator.scan")}
                                    </Button>
                                </Grid>
                                </>
                            }
                        </Grid>
                    </Card>
                </Grid>

                <Dialog open={qrScanner} onClose={closeQrScanner}
                    sx={{
                        // width: {xs: "100%", sm: "80%", md: "40%"}, 
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "80%", md: "60%"},
                            },
                        },
                    }}
                > 
                    <Box sx={{width: "100%", minWidth: "20rem", minHeight: "20rem"}}>
                        <QrReader
                            delay={300}
                            onError={handleError}
                            onResult=   {handleScan}
                            style={{ width: '100%', height: "100%"}}
                            containerStyle={{width: '100%'}}
                            videoContainerStyle={{width: '100%'}}
                            videoStyle={{width: '100%'}}
                            constraints={constraints}
                        />
                    </Box>
                    <DialogActions>
                        <Button onClick={closeQrScanner} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={paymentDone} onClose={closePaymentDone} 
                    sx={{
                        // width: {xs: "100%", sm: "80%", md: "40%"}, 
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "100%", sm: "80%", md: "30%"},
                            },
                        },
                    }}>
                    <Grid container>
                        <Grid item xs={8} sm={8} md={8} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem"}}>
                            <Box
                                component="img"
                                sx={{
                                    height: {xs: 30, sm: 35},
                                    width: {xs: 188, sm: 210},
                                }}
                                alt="Logo"
                                src="/images/creditcards/logo-opencard.png"
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} sx={{display: "flex", alignItems: "center", justifyContent: "end", marginTop: "1rem", paddingRight: "1rem"}}>
                            <Box
                                component="img"
                                sx={{
                                    height: {xs: 90, sm: 100},
                                    width: {xs: 90, sm: 100},
                                }}
                                alt="Sello"
                                src={Seal}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "0.8rem", marginBottom: "0.5rem"}}>
                            <Divider/>
                        </Grid>
                    </Grid>
                    <DialogContent>
                        {paymentSeal}                 
                    </DialogContent>
                    <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                        <Button onClick={closePaymentDone} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            OK
                        </Button>
                        
                    </DialogActions>
                </Dialog>
                <Dialog open={otpBox} onClose={handleTimerEnd} 
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "100%", sm: "80%", md: "25%"},
                            },
                        },
                    }}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem"}}>
                            <CountDownTimer duration={60} onTimerEnd={handleTimerEnd}/>
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "0.8rem", marginBottom: "0.5rem"}}>
                            <Divider/>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1.5rem"}}>
                            <TextField
                                label={<Typography align="center" sx={{textAlign: "center"}}>{t("PurchaseSimulator.otpInstructions")}</Typography>}
                                variant="standard"
                                inputProps={{ maxLength: 6, style: {textAlign: "center", letterSpacing: "0.2rem", fontWeight: "bold", color: "black"}}}
                                onChange={otpChange}
                                sx={{
                                    "& label": {
                                        width: "100%",
                                        textAlign: "center",
                                        color: "black",
                                        transformOrigin: "center",
                                            "&.Mui-focused": {
                                            transformOrigin: "center"
            }
         }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                        <Button disabled={otpDisabled} onClick={otpValidation} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "3rem"}}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </>    

            </Box>
            }
        </Box>
    )
}
