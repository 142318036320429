import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';


const availableAssets = [
    {
        asset: "USD",
        file: "/images/Currencies/usdt-token.png",
        label: "US Dollar",
    },
    {
        asset: "GHS",
        file: "/images/Currencies/ghana-token.png",
        label: "Ghana Cedi",
    
    },
    {
        asset: "EUR",
        file: "/images/Currencies/euro-token.png",
        label: "Euro",
    },
];

export default function AssetsMenu(props) {

    const {currencySelected, setCurrencySelected, handleAssetSelection } = props;

    const assetSelection = (asset) => {
        console.log(asset.label);
        setCurrencySelected(asset.label);
        handleAssetSelection(asset);
    }

    // const currencySelectedChange = (e) => {
    //     setCurrencySelected(e.target.value);
    // }

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Grid container>
           <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                <TextField
                    sx={{textAlign: "start", width: {xs: "80%", sm: "50%", md: "30%"}, 
                    }}
                    variant="standard"
                    fullWidth={true}
                    // id="asset"
                    // name="asset"
                    select
                    label={<Typography variant="body2">Destination Currency</Typography>}
                    value={currencySelected}
                    // onChange={(e)=>currencySelectedChange(e)}
                    autoComplete="off"
                >
                    {availableAssets.map((el) => (
                        <MenuItem key={generateRandomKey()} value={el.label} onClick={() => assetSelection(el)}>
                            <Grid container key={generateRandomKey()}>
                                <Grid item xs={3} sm={3} md={3} key={generateRandomKey()} sx={{display:"flex", alignItems: "center"}}>
                                    <Box component="img" src={el.file} alt="Currency symbol" sx={{ width: "1.8rem", height: "1.8rem" }} key={generateRandomKey()}/>                                    
                                </Grid>
                                <Grid item xs={9} sm={9} md={9} key={generateRandomKey()} sx={{display:"flex", alignItems: "center"}}>
                                    <Typography key={generateRandomKey()}>{el.label}</Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    ))}
                </TextField> 
            </Grid>
        </Grid>
      );
}
