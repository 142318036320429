import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/react";
import { getUserWalletWithUsd} from "../utilityFunctions/WalletUtil";
import { PuffLoader } from "react-spinners";
import Colors from "../components/ui/Colors";
import swal from 'sweetalert';
import { Button, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useTranslation } from 'react-i18next';


const assetsImg = [
    {
        asset: "USD",
        file: "/images/Currencies/usdt-token.png",
        label: "US Dollar",
    },
    {
        asset: "EUR",
        file: "/images/Currencies/euro-token.png",
        label: "Euro",
    },
    {
        asset: "COP",
        file: "/images/Currencies/cop-token.png",
        label: "Colombian Peso",
    },
    {
        asset: "GHS",
        file: "/images/Currencies/ghana-token.png",
        label: "Ghana Cedi",
    },
];

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function HandleWallet(props) {

    const { loginUser} = props;

    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    React.useEffect(() => {
        renderWalletAccounts();
    }, []);

    const initialValues = async () => {
        setLoading(true);
        const userWallet = await getUserWalletWithUsd(loginUser.jwtToken);
        setLoading(false);

        console.log(userWallet);

        if(!userWallet){
            return;
        }

        return userWallet;
    }

    const[accountsRender, setAccountsRender] = useState([]);

    const renderWalletAccounts = async () => {
        
        const userWallet = await initialValues();

        if(!userWallet){
            return;
        };

        try{
            
            setAccountsRender(
            <Grid container spacing={1} sx={{justifyContent: {xs: "center", sm: "center"}, alignItems: "start"}}> 
                {userWallet.walletAccountRequestDao.map((account) => {
                    return(
                        <Grid key={generateRandomKey()} item xs={12} sm={4} md={4} lg={4} sx={{display: "flex", justifyContent: "center", marginTop: "0.5rem", maxWidth:{xs: "350px"}, marginBottom: "0.5rem"}}>
                            <Card key={generateRandomKey()} sx={{width: "90%", marginTop: "1rem"}}>
                                <Grid container direction="column" key={generateRandomKey()}>
                                    <Grid key={generateRandomKey()} item xs={12} sm={12} md={12}>
                                        <CardActions key={generateRandomKey()}
                                            sx={{cursor: "pointer"}}
                                            onClick={()=>toPayment(account)}
                                            disabled={account.balance <= 0}>
                                            <Grid key={generateRandomKey()} container>
                                                <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{width: "85%", display: "flex", justifyContent: "center", marginTop: "0.2rem", marginBottom: "0.2rem"}}>
                                                    <CardMedia
                                                        sx={{width: "3rem", height: "3rem", borderRadius: "50%", marginLeft: "0.5rem"}}
                                                        key={generateRandomKey()}
                                                        component="img"
                                                        image={assetsImg.filter((asset) => asset.asset === account.asset)[0].file}
                                                        alt="Currency symbol"
                                                        />
                                                </Grid>
                                                <Grid item xs={10} sm={10} md={10} key={generateRandomKey()} sx={{textAlign: "end", display: "flex", alignItems: "center", justifyContent: "end"}}>
                                                <Typography key={generateRandomKey()} variant="body1" sx={{color: "GrayText"}}>
                                                    {/* {account.asset === "GHS" ? "CEDI" : `${account.asset}`} */}
                                                    {assetsImg.filter((asset) => asset.asset === account.asset)[0].label}
                                                </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} key={generateRandomKey()}>  
                                                    <Grid key={generateRandomKey()} container direction="row"
                                                        sx={{display: "flex", justifyContent: "center", marginTop: "0.2rem", marginBottom: "0.2rem", textAlign:"left", marginRight: "0.6em"}}>
                                                        <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} >
                                                        <Typography key={generateRandomKey()} variant="h6" sx={{fontWeight: "bold"}} >
                                                            {`${new Intl.NumberFormat('en-US').format(account.balance)}`}
                                                        </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} key={generateRandomKey()}>
                                                            <Typography key={generateRandomKey()} sx={{fontSize: "0.75rem", color: "#808080"}}>
                                                                {`${new Intl.NumberFormat('en-US').format(account.usdBalance)} USD`}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid key={generateRandomKey()}  item xs={12} sm={12} md={12} lg={12} onClick={()=>toPayment(account)} sx={{display: "flex", justifyContent: "end", textAlign: "end", cursor: "pointer"}}>
                                                            <ArrowCircleRightIcon sx={{color: "#03a9f4", fontSize: "2rem"}}/>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardActions>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} key={generateRandomKey()} onClick={() => toAccountStatement(account)} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Button key={generateRandomKey()} 
                                        sx={{fontSize: "0.65em", cursor: "pointer", color: "#03a9f4", fontWeight: "bold"}}>
                                            {t("HandleWallet.detail")}
                                        </Button>
                                    </Grid> 
                                </Grid>
                            </Card> 
                        </Grid>
                    )

                })}
            </Grid>
            )
            
            setLoading(false);

        }catch(error){
            console.log(error);
            swal({
            title: "No Wallets Found",
            icon: "error",
            buttons: "Ok"
            }).then(()=>{
            history.push('/account/create_account');
            });
        }
        }

        const toPayment = (account) => {
            console.log(account);
            history.push({
                pathname: "/wallet/payments/",
                state:{
                    account: account
                }
            })
        };

        const toAccountStatement = (account) => {
            history.push({
                pathname: "/wallet/acounts/statement",
                state: {
                    sourceAccount: account,
                }
            })
        };

        const generateRandomKey = () => {
            const randomString = Math.random().toString(36).slice(2);
            const timestamp = Date.now().toString(36);
            return randomString + '_' + timestamp;
        };

    return (
    <Box sx={{display: "flex", justifyContent: "center",  width: "100%", height: "100%", backgroundColor: "#f6f5f3"}}>
        <Box
            sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            width: {xs: "100%", sm: "100%", md: "100%"},
            height: "100%",
            }}
        >
        {loading ?
            <PuffLoader size={100} color={Colors.secondary} css={cssLoader} />
        :
            <>
                    {accountsRender}
            </>
        }
        </Box>
    </Box>
    )
}
