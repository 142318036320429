import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, Typography, DialogContent } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// import CardImg from "../img/creditcards/card2.png";
import CardImg from "../img/creditcards/cardClean.png";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../components/ui/Colors";
import {createCardPayment, cardBlockingCall, purchaseOtp, purchaseOtpVerification, cardunBlockingCall} from "../utilityFunctions/OpenCardUtil";
import swal from "sweetalert";
import Seal from "../img/creditcards/success.png";
import NumberFormat from "react-number-format";
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import CountDownTimer from './CountDownTimer';
import { useTranslation } from 'react-i18next';
import { useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    overlayContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: "100%",
        padding: "8px",
        borderRadius: '5px',
    },
}));

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
};

const dateOptionsHour = {
    // weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: 'numeric',
    minute: 'numeric',
};

const paymentObject = {
    username: "",
    internalCardCode: "",
    operationAmount: "",
};

let isPayment = false;

const blockingObject = {
    username: "",
    plasticBlockade: "",
    internalCardCode: "",
};

const otpVerifyObject = {
    id: "",
    otp: "",
};

export default function HandleCreditCard(props) {

    const {loginUser} = props;

    const history = useHistory();

    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);

    const { t } = useTranslation();

    React.useEffect(()=> {
        isPayment = false;
        paymentObject.username = loginUser.accountId;
        setLoading(true);
        renderPorducts();
    }, []);
    
    const[products, setProducts] = React.useState("");

    const renderPorducts = () => {
        
        if(!props.location.state || !props.location.state.products ||!props.location.state.products.listGeneralProduct){
            console.log("props = null");
            return;
        };

        const productsInfo = props.location.state.products.listGeneralProduct;

        console.log(productsInfo);

        setProducts(
            <Grid container spacing={2} sx={{justifyContent: {xs: "center", sm: "center"}}}>
                {productsInfo.map((creditCard) => {
                    const initialBin=creditCard.bin[0];
                    return(
                        <Grid key={generateRandomKey()} item xs={12} sm={6} md={4} lg={4} sx={{ display: "flex", justifyContent: "center", marginTop: {xs: "3rem", sm: "2rem", md: "0.5rem"}, marginBottom: "0.5rem"}}>
                            <Card key={generateRandomKey()} sx={{maxWidth: "400px", minWidth: {xs: "80%", sm: "90%", md: "300px", borderRadius: "5%"}}}>
                                <CardActions key={generateRandomKey()} onClick={() => toPayment(creditCard)} sx={{cursor: "pointer"}}>
                                    <Grid key={generateRandomKey()} container>
                                        <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} lg={12} sx={{width: "90%", display: "flex", justifyContent: "center", position: "relative"}}>
                                            {initialBin && initialBin === "3" ?
                                            <CardMedia
                                                component="img"
                                                // image={CardImg}
                                                image= "/images/creditcards/card_Amex2.png"
                                                alt="Card"
                                            />  
                                            : initialBin && initialBin === "4" ?
                                            <CardMedia
                                                component="img"
                                                // image={CardImg}
                                                image= "/images/creditcards/opencard-card.png"
                                                alt="Card"
                                            />
                                            : initialBin && initialBin === "5" ?
                                            <CardMedia
                                                component="img"
                                                // image={CardImg}
                                                image= "/images/creditcards/card_mc.png"
                                                alt="Card"
                                            />
                                            :
                                            <CardMedia
                                                component="img"
                                                // image={CardImg}
                                                image= "/images/creditcards/card_brand.png"
                                                alt="Card"
                                            />
                                            }
                                            <Box className={classes.overlayContainer} key={generateRandomKey()}>
                                                <Typography key={generateRandomKey()} variant="h6" sx={{color: "black", textAlign: "center", marginTop: {xs: "37%", sm: "39%"}, letterSpacing: '0.2rem'}}>{creditCard.cardNumber}</Typography>
                                            </Box>
                                            {creditCard.productName &&
                                            <Box key={generateRandomKey()} sx={{position: 'absolute', bottom: 0, left: 0, width: '100%', height: "100%", padding: "8px", borderRadius: '5px',}}>
                                                <Typography key={generateRandomKey()} sx={{fontSize: {xs: "0.5rem", sm: "0.6rem"}, color: "black", fontWeight: "bold", textAlign: "start", marginTop: {xs: "5%", sm: "5%"}, marginLeft:"2%", letterSpacing: '0.01rem'}}>{creditCard.productName}</Typography>
                                            </Box>
                                            }
                                        </Grid>
                                        {creditCard.plasticBlockade && (creditCard.plasticBlockade==="50" || creditCard.plasticBlockade==="X" || creditCard.plasticBlockade==="Y" || creditCard.plasticBlockade==="A" || creditCard.plasticBlockade==="B" || creditCard.plasticBlockade==="O" || creditCard.plasticBlockade==="P" || creditCard.plasticBlockade==="0" || creditCard.plasticBlockade==="1" || creditCard.plasticBlockade==="E")?
                                        <>
                                        <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} lg={12} sx={{width: "90%", display: "flex", justifyContent: "center", textAlign: "center", marginTop: "0.5rem",}}>
                                            <Typography key={generateRandomKey()} variant="body1" sx={{color: "red", fontWeight: "bold"}}>{t("HandleCreditCard.cardBlocked")}</Typography>
                                        </Grid>
                                        <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} lg={12} sx={{width: "90%", display: "flex", justifyContent: "center", textAlign: "center", marginTop: "0.5rem", marginBottom: "2.5rem"}}>
                                            <Typography key={generateRandomKey()} variant="h4" sx={{color: "black"}}>{`$ ${new Intl.NumberFormat('es-CO').format(creditCard.availableCrLImit)}`}</Typography>
                                        </Grid>
                                        </>
                                        : creditCard.plasticBlockade && (creditCard.plasticBlockade==="C" || creditCard.plasticBlockade==="N" || creditCard.plasticBlockade==="V") ?
                                        <>
                                        <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} lg={12} sx={{width: "90%", display: "flex", justifyContent: "center", textAlign: "center", marginTop: "0.5rem",}}>
                                            <Typography key={generateRandomKey()} variant="body1" sx={{color: "red", fontWeight: "bold"}}>{t("HandleCreditCard.cardCancelled")}</Typography>
                                        </Grid>
                                        <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} lg={12} sx={{width: "90%", display: "flex", justifyContent: "center", textAlign: "center", marginTop: "0.5rem", marginBottom: "2.5rem"}}>
                                            <Typography key={generateRandomKey()} variant="h4" sx={{color: "black"}}>{`$ ${new Intl.NumberFormat('es-CO').format(creditCard.availableCrLImit)}`}</Typography>
                                        </Grid>
                                        </>
                                        :
                                        <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} lg={12} sx={{width: "90%", display: "flex", justifyContent: "center", textAlign: "center", marginTop: "2.5rem", marginBottom: "2.5rem"}}>
                                            <Typography key={generateRandomKey()} variant="h4" sx={{color: "black"}}>{`$ ${new Intl.NumberFormat('es-CO').format(creditCard.availableCrLImit)}`}</Typography>
                                        </Grid>
                                        }
                                        <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                            <Grid key={generateRandomKey()} container sx={{width: {xs: "90%", sm:"90%", md: "80%"}}}>
                                                <Grid key={generateRandomKey()} item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                                    <Typography key={generateRandomKey()} variant="body2">{`${t("HandleCreditCard.paymentDate")}:`}</Typography>
                                                </Grid>
                                                <Grid key={generateRandomKey()} item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "end", textAlign: "end"}}>
                                                    <Typography key={generateRandomKey()} variant="body2">{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(creditCard.importantDate.instantPaymentLimitDate))}`}</Typography>
                                                </Grid>
                                                <Grid key={generateRandomKey()} item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                                    <Typography key={generateRandomKey()} variant="body2">{`${t("HandleCreditCard.minumumPayment")}:`}</Typography>
                                                </Grid>
                                                <Grid key={generateRandomKey()} item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "end", textAlign: "end"}}>
                                                    <Typography key={generateRandomKey()} variant="body2">{`$ ${new Intl.NumberFormat('es-CO').format(creditCard.lowestpayment)}`}</Typography>
                                                </Grid>
                                                <Grid key={generateRandomKey()} item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                                    <Typography key={generateRandomKey()} variant="body2">{`${t("HandleCreditCard.totalPayment")}:`}</Typography>
                                                </Grid>
                                                <Grid key={generateRandomKey()} item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "end", textAlign: "end"}}>
                                                    <Typography key={generateRandomKey()} variant="body2">{`$ ${new Intl.NumberFormat('es-CO').format(creditCard.totalPayment)}`}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                                <Grid key={generateRandomKey()} container>
                                    <Grid item xs={4} sm={4} md={4} key={generateRandomKey()} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2rem"}}>
                                        <Button key={generateRandomKey()} onClick={()=>toStatement(creditCard)} sx={{fontSize: "0.9rem", cursor: "pointer", color: "#03a9f4", fontWeight: "bold"}}>
                                            {t("HandleCreditCard.detail")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} key={generateRandomKey()} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2rem"}}>
                                        <Button key={generateRandomKey()} onClick={() => toPayment(creditCard)} sx={{fontSize: "0.9rem", cursor: "pointer", color: "#03a9f4", fontWeight: "bold"}}>
                                            {t("HandleCreditCard.pay")}
                                        </Button>
                                    </Grid> 
                                    <Grid item xs={4} sm={4} md={4} key={generateRandomKey()} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2rem"}}>
                                        {!creditCard.plasticBlockade || (creditCard.plasticBlockade && creditCard.plasticBlockade==="1") ?
                                            <Button key={generateRandomKey()} onClick={()=>toBlockingBox(creditCard)} sx={{fontSize: "0.9rem", cursor: "pointer", color: "#03a9f4", fontWeight: "bold", paddingRight: "1.5rem"}}>
                                                {t("HandleCreditCard.block")}
                                            </Button>
                                        :
                                            <Button key={generateRandomKey()} disabled={true} onClick={()=>toBlockingBox(creditCard)} sx={{fontSize: "0.9rem", cursor: "pointer", color: "#03a9f4", fontWeight: "bold", paddingRight: "1.5rem"}}>
                                                {t("HandleCreditCard.block")}
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
        );

        setLoading(false);
    }

    const[paymentDialog, setPaymentDialog] = React.useState(false);

    const closePaymentDialog = () => {
        setPaymentDialog(false);
    };

    const[paymentAction, setPaymentAction] = React.useState("");

    const toPayment = (product) => {
        
        setPaymentAction(
            <List sx={{width: "100%",}}>
                <Grid container spacing={2}>
                    <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                        <ListItemButton  onClick={() => handleMinimumPayment(product)}>
                            <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                <Typography variant="body2">{`${t("HandleCreditCard.minumumPayment")}:`}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "end", textAlign: "end"}}>
                                <Typography variant="body2">{`$ ${new Intl.NumberFormat('es-CO').format(product.lowestpayment)}`}</Typography>
                            </Grid>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                        <ListItemButton  onClick={() => handleTotalPayment(product.totalPayment)}>
                            <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                <Typography variant="body2">{`${t("HandleCreditCard.totalPayment")}:`}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "end", textAlign: "end"}}>
                                <Typography variant="body2">{`$ ${new Intl.NumberFormat('es-CO').format(product.totalPayment)}`}</Typography>
                            </Grid>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <Grid item xs={6} sm={4} md={3} lg={3} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                            <Typography variant="body2">{`${t("HandleCreditCard.otherValue")}:`}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={8} md={9} lg={9} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                            <NumberFormat
                                variant="standard"
                                style={{width: "95%", height: "50%"}}
                                name="inputPayment"
                                customInput={TextField}
                                prefix={'$ '}
                                type="inputPayment"
                                label={<Typography variant="body2">{t("HandleCreditCard.value")}</Typography>}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                autoComplete="off"
                                value={inputPayment}
                                onValueChange={(values) => {
                                    valueChange(values, product);
                                }}
                            />
                        </Grid>
                    </ListItem>
                </Grid>
            </List>
        );
        
        // setIsPayment(false);
        setPaymentDialog(true);
                                
    };

    const paymentWarning = () =>{
        return new Promise((resolve, reject) => {
            swal({
                title: t("HandleCreditCard.confirmPaymentTitle"),
                text: t("HandleCreditCard.confirmPaymentText"),
                icon: "info",
                buttons: [t("HandleCreditCard.confirmPaymentBack"), t("HandleCreditCard.confirmPaymentContinue")]
            }).then((result) => {
                resolve(result);
            });
        })
        
    }

    const handleMinimumPayment = async (product) => {

        const paymentEnabled = await paymentWarning();
        
        console.log(paymentEnabled);
        if(!paymentEnabled){
            setPaymentDialog(false);
            return;
        }
        else{
            console.log(product);
            paymentObject.operationAmount = product.lowestpayment;
            paymentObject.internalCardCode = product.internalCode;

            setLoading(true);
            const paymentResponse = await createCardPayment(paymentObject, loginUser.jwtToken);
            setLoading(false);
            setPaymentDialog(false);

            if(!paymentResponse){
                swal({
                    title: t("HandleCreditCard.unsuccessfulPaymentTitel"),
                    text: t("HandleCreditCard.unsuccessfulPaymentText"),
                    icon: "error",
                    button: "ok",
                }).then(() => {
                    setPaymentDialog(false);
                });
                
                return;
            }

            swal({
                title: t("HandleCreditCard.successfulPaymentTitle"),
                icon: "success",
                button: "ok",
            }).then(() => {
                setPaymentDialog(false);
                renderPaymentSeal(paymentResponse);
            });
        }
            
    };


    const handleTotalPayment = async (product) => {

        const paymentEnabled = await paymentWarning();
        
        console.log(paymentEnabled);
        if(!paymentEnabled){
            setPaymentDialog(false);
            return;
        }
        else{
            paymentObject.operationAmount = product.totalPayment;
            paymentObject.internalCardCode = product.internalCode;
            
            setLoading(true);
            const paymentResponse = await createCardPayment(paymentObject, loginUser.jwtToken);
            setLoading(false);
            setPaymentDialog(false);

            if(!paymentResponse){
                swal({
                    title: t("HandleCreditCard.unsuccessfulPaymentTitel"),
                    text: t("HandleCreditCard.unsuccessfulPaymentText"),
                    icon: "error",
                    button: "ok",
                }).then(() => {
                    setPaymentDialog(false);
                });
                
                return;
            }

            swal({
                title: t("HandleCreditCard.successfulPaymentTitle"),
                icon: "success",
                button: "Súper",
            }).then(() => {
                setPaymentDialog(false);
                renderPaymentSeal(paymentResponse);
            });
        }
    };

    const[inputPayment, setInputPayment] = React.useState("");

    const handleInputPayment = async () => {

        const paymentEnabled = await paymentWarning();
        
        console.log(paymentEnabled);
        if(!paymentEnabled){
            setPaymentDialog(false);
            return;
        }

        setLoading(true);
        const paymentResponse = await createCardPayment(paymentObject, loginUser.jwtToken);
        setLoading(false);
        setPaymentDialog(false);

        if(!paymentResponse){
            swal({
                title: t("HandleCreditCard.unsuccessfulPaymentTitel"),
                text: t("HandleCreditCard.unsuccessfulPaymentText"),
                icon: "error",
                button: "ok",
            }).then(() => {
                setPaymentDialog(false);
            });
            
            return;
        }

        swal({
            title: t("HandleCreditCard.successfulPaymentTitle"),
            icon: "success",
            button: "Súper",
        }).then(() => {
            setPaymentDialog(false);
            renderPaymentSeal(paymentResponse);
        });
    };

    const valueChange = (e, product) => {

        if(!e.floatValue){
            isPayment = false;
        }else{
            isPayment = true;
        }
        
        if(e.floatValue > product.totalPayment){
            setInputPayment("");
            swal({
                title: t("HandleCreditCard.maxValueTitle"),
                text: t("HandleCreditCard.maxValueText"),
                icon: "info",
                button: "ok",
            });
            toPayment(product);
            return;
        }

        setInputPayment(e.floatValue);
        paymentObject.operationAmount = e.floatValue;
        paymentObject.internalCardCode = product.internalCode;

    };

    const toStatement = (product) => {
        console.log("to statement...")

        history.push({
            pathname: "/creditCard/statement",
            state:{
                product: product,
            }
        });
    };

    const[paymentSeal, setPaymentSeal] = React.useState("");

    const renderPaymentSeal = (payment) => {
        
        setPaymentSeal(
            <List sx={{width: "100%",}}>
                <Grid container spacing={2}>
                    <ListItem disablePadding sx={{marginBottom: "0.8rem"}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                            <Typography variant="body2" sx={{color: "gray", fontSize: "0.8rem"}}>{`${new Intl.DateTimeFormat('es-CO', dateOptionsHour).format(new Date(Date.now()))}`}</Typography>
                        </Grid>
                    </ListItem>
                    <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                            <Typography variant="h6" sx={{color: "green"}}>{`$ ${new Intl.NumberFormat('es-CO').format(paymentObject.operationAmount)}`}</Typography>
                        </Grid>
                    </ListItem>
                    <ListItem disablePadding sx={{marginBottom: "0"}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                            <Typography variant="body2" sx={{color: "gray", fontSize: "0.7rem"}}>{t("HandleCreditCard.reference")}:</Typography>
                        </Grid>
                    </ListItem>
                    <ListItem disablePadding sx={{marginBottom: "1rem"}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                            <Typography variant="body1" sx={{color: "black"}}>{`${payment.trasactionNumber}`}</Typography>
                        </Grid>
                    </ListItem>
                </Grid>
            </List>
        );

        setPaymentDone(true);
    };

    const[paymentDone, setPaymentDone] = React.useState(false);

    const closePaymentDone = () => {
        setPaymentDone(false);
    };

    const [renderBlocking, setRenderBlocking] = React.useState("");
    
    const toBlockingBox = (product) => {
        
        setRenderBlocking(
            <List sx={{width: "100%", marginTop: "1rem"}}>
                {product.plasticBlockade && product.plasticBlockade==="1"?
                <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                    <ListItemButton  onClick={() => handleOtp(product)}>
                        <Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                <ListItemIcon>
                                    <CreditScoreIcon sx={{color: "#03a9f4"}}/>
                                </ListItemIcon>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                <Typography>{t("HandleCreditCard.unblockCard")}</Typography>
                            </Grid>
                        </Grid>
                    </ListItemButton>
                </ListItem>
                :
                <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                    <ListItemButton  onClick={()=>toBlocking(product)}>
                        <Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                <ListItemIcon>
                                    <CreditCardOffIcon sx={{color: "#03a9f4"}}/>
                                </ListItemIcon>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                <Typography>{t("HandleCreditCard.blockCard")}</Typography>
                            </Grid>
                        </Grid>
                    </ListItemButton>
                </ListItem>
                }
            </List>
        );

        setOpenBlocking(true);
    };

    const toBlocking = async (product) => {
        closeBlocking();

        blockingObject.username=loginUser.accountId;
        blockingObject.plasticBlockade="1";
        blockingObject.internalCardCode=product.internalCode;

        setLoading(true);
        const blockingResponse = await cardBlockingCall(blockingObject, loginUser.jwtToken);
        

        if(!blockingResponse){
            setLoading(false);
            swal({
                title: "Error",
                text: t("HandleCreditCard.unsuccessfulPaymentText"),
                icon: "error",
                button: "ok",
            });
            return;
        };

        swal({
            title: t("HandleCreditCard.successfulBlock"),
            icon: "success",
            button: "ok",
        }).then(()=>{
            history.push("/creditCard/mount");
            setLoading(false);
            return;
        });
        
    };

    const toUnblocking = async (product) => {
        blockingObject.username=loginUser.accountId;
        blockingObject.plasticBlockade="1";
        // blockingObject.internalCardCode=product.internalCode;

        setLoading(true);
        const blockingResponse = await cardunBlockingCall(blockingObject, loginUser.jwtToken);
        

        if(!blockingResponse){
            setLoading(false);
            swal({
                title: "Error",
                text: t("HandleCreditCard.unsuccessfulPaymentText"),
                icon: "error",
                button: "ok",
            });
            return;
        };
        
        swal({
            title: t("HandleCreditCard.successfulUnblock"),
            icon: "success",
            button: "ok",
        }).then(()=>{
            history.push("/creditCard/mount");
            setLoading(false);
            return;
        });
    };

    const[otpBox, setOtpBox] = React.useState(false);
    const[otpDisabled, setOtpDisabled] = React.useState(true);

    const handleTimerEnd = () => {
        setOtpBox(false);
    };

    const otpChange = (e) => {
        if(e.target.value && e.target.value.length === 6){
            otpVerifyObject.otp=e.target.value;
            setOtpDisabled(false);
        }
        else{
            setOtpDisabled(true);
        }
    };

    const handleOtp = async (product) => {
        
        closeBlocking();
        blockingObject.internalCardCode=product.internalCode;
        
        const otpRequest = {
            username: loginUser.accountId,
        }

        setLoading(true);
        const otpResponse = await purchaseOtp(otpRequest, loginUser.jwtToken);
        setLoading(false);

        if(!otpResponse){
            swal({
                title: "Error",
                text: t("HandleCreditCard.unsuccessfulPaymentText"),
                icon: "error",
                button: "ok",
            });
            return;
        };

        otpVerifyObject.id=otpResponse.id;

        setOtpBox(true);

    };

    const otpValidation = async () => {
        setOtpBox(false);
        setLoading(true);
        const otpResponse = await purchaseOtpVerification(otpVerifyObject, loginUser.jwtToken);
        setLoading(false);

        if(!otpResponse){
            swal({
                title: "Error",
                text: t("HandleCreditCard.unsuccessfulPaymentText"),
                icon: "error",
                button: "ok",
            });
            return;
        };

        toUnblocking();
    };

    const[openBlocking, setOpenBlocking] = React.useState(false);

    const closeBlocking = () => {
        setOpenBlocking(false);
    }

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center",  width: "100%", height: "100%", backgroundColor: "#ededed",}}>
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center", md: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                    
                }}
            >
                {products}
                <Dialog open={paymentDialog} onClose={closePaymentDialog}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem"}}>
                            <Box
                                component="img"
                                sx={{
                                    height: 35,
                                    width: 210,
                                }}
                                alt="Logo"
                                src="/images/creditcards/logo-opencard.png"
                            />
                        </Grid>
                    </Grid>
                    <DialogContent>
                        {paymentAction}                 
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closePaymentDialog} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            {t("HandleCreditCard.back")}
                        </Button>
                        {isPayment?
                        <Button onClick={handleInputPayment} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            {t("HandleCreditCard.pay")}
                        </Button>
                        :<></>
                        }
                    </DialogActions>
                </Dialog>
                <Dialog open={paymentDone} onClose={closePaymentDone} 
                    sx={{
                        // width: {xs: "100%", sm: "80%", md: "40%"}, 
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "100%", sm: "80%", md: "30%"},
                            },
                        },
                    }}>
                    <Grid container>
                        <Grid item xs={8} sm={8} md={8} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem"}}>
                            <Box
                                component="img"
                                sx={{
                                    height: 35,
                                    width: 210,
                                }}
                                alt="Logo"
                                src="/images/creditcards/logo-opencard.png"
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} sx={{display: "flex", alignItems: "center", justifyContent: "end", marginTop: "1rem", paddingRight: "1rem"}}>
                            <Box
                                component="img"
                                sx={{
                                    height: 100,
                                    width: 100,
                                }}
                                alt="Sello"
                                src={Seal}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "0.8rem", marginBottom: "0.5rem"}}>
                            <Divider/>
                        </Grid>
                    </Grid>
                    <DialogContent>
                        {paymentSeal}                 
                    </DialogContent>
                    <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                        <Button onClick={closePaymentDone} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            OK
                        </Button>
                        
                    </DialogActions>
                </Dialog>
                <Dialog open={openBlocking} onClose={closeBlocking} 
                    sx={{
                        // width: {xs: "100%", sm: "80%", md: "40%"}, 
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "100%", sm: "80%", md: "30%"},
                            },
                        },
                    }}>
                    <DialogContent>
                        {renderBlocking}                 
                    </DialogContent>
                    <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                        <Button onClick={closeBlocking} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            {t("HandleCreditCard.back")}
                        </Button>
                        
                    </DialogActions>
                </Dialog>
                <Dialog open={otpBox} onClose={handleTimerEnd} 
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "100%", sm: "80%", md: "25%"},
                            },
                        },
                    }}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem"}}>
                            <CountDownTimer duration={60} onTimerEnd={handleTimerEnd}/>
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "0.8rem", marginBottom: "0.5rem"}}>
                            <Divider/>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1.5rem"}}>
                            <TextField
                                label={<Typography align="center" sx={{textAlign: "center"}}>Ingresa el código</Typography>}
                                variant="standard"
                                inputProps={{ maxLength: 6, style: {textAlign: "center", letterSpacing: "0.2rem", fontWeight: "bold", color: "black"}}}
                                onChange={otpChange}
                                sx={{
                                    "& label": {
                                        width: "100%",
                                        textAlign: "center",
                                        color: "black",
                                        transformOrigin: "center",
                                            "&.Mui-focused": {
                                            transformOrigin: "center"
                                    }
                                }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                        <Button disabled={otpDisabled} onClick={otpValidation} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "3rem"}}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            }
        </Box>
    )
}
