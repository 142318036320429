import {wupoServerUri} from '../Api';

const createOpenCardProduct = async(requestObject, jwtToken) => {
  

    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/clientOpenCard`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const product = await response.json();
      console.log(product);
  
      return product;
    }
  
    else {
      return null;
    }
}

const getProductInfo = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/cardInfoOpenCard`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const product = await response.json();
      console.log(product);
  
      return product;
    }
  
    else {
      return null;
    }
}

const createCardPayment = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/paymentOpenCard`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const payment = await response.json();
      console.log(payment);
  
      return payment;
    }
  
    else {
      return null;
    }
}

const getCardStatement = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/transactionsOpenCard`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const transactions = await response.json();
      console.log(transactions);
  
      return transactions;
    }
  
    else {
      return null;
    }
}

const purchaseOtp= async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/paymentOtp`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const otpResponse = await response.json();
    console.log(otpResponse);

    return otpResponse;
  }

  else {
    return null;
  }
}

const purchaseOtpVerification= async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/paymentOtpVerification`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const otpResponse = await response.json();
    console.log(otpResponse);

    return otpResponse;
  }

  else {
    return null;
  }
}

const purchaseAuth = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/authOpenCard`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const authResponse = await response.json();
      console.log(authResponse);
  
      return authResponse;
    }
  
    else {
      return null;
    }
}

const qrPayment = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/qrpayment`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const qrResponse = await response.blob();
    console.log(qrResponse);

    return qrResponse;
  }

  else {
    return null;
  }
}

const loansBatchUpload = async(requestObject, jwtToken) => {
  
  console.log(requestObject.get("loansFile"));

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/parseLoansFile`, {
      method: "POST",
      body: requestObject,
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    console.log("ok");

    return;
  }

  else {
    return null;
  }
}

const paymentsBatchUpload = async(requestObject, jwtToken) => {
  
  console.log(requestObject.get("paymentsFile"));

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/parsePaymentsFile`, {
      method: "POST",
      body: requestObject,
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    console.log("ok");

    return;
  }

  else {
    return null;
  }
}

const definitionsUpload = async(requestObject, jwtToken) => {
  
  console.log(requestObject.get("definitionsFile"));

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/parseDefinitionsFile`, {
      method: "POST",
      body: requestObject,
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    
    console.log("ok");

    return;
  }

  else {
    return null;
  }
}

const cardBlockingCall = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/blockingOpenCard`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const cardunBlockingCall = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/unblockingOpenCard`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
};

const openCardCreditRisk = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/creditRiskOpenCard`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

  export{ createOpenCardProduct, getProductInfo, createCardPayment, getCardStatement, purchaseAuth, qrPayment, purchaseOtp,
    purchaseOtpVerification, loansBatchUpload, paymentsBatchUpload, definitionsUpload, cardBlockingCall, openCardCreditRisk,
    cardunBlockingCall }