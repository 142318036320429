import React from 'react';
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {wupoServerUri} from "../Api";
import {createOpenCardProduct, openCardCreditRisk} from "../utilityFunctions/OpenCardUtil";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, DialogContent, Typography } from "@mui/material";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Colors from "../components/ui/Colors";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardImg from "../img/creditcards/card2.png";
import Link from '@mui/material/Link';
import Bancolombia from "../img/creditcards/logo-bancolombia.png";
import { useTranslation } from 'react-i18next';
import { useHistory} from "react-router-dom";


const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

//CONSTANTS FOR THE DEMO
const creditScore = "A"; // A = ingresos * 1, B = ingresos 0.5, C = negado
const income = 4000000;
const interestRate = 0.24;

const CreateClientRequest = {
    username: "",
    approvedPurchaseLimit: "",
};

export default function CreateCreditCard(props) {

    const{loginUser} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const { t } = useTranslation();

    const[cardApproved, setCardApproved] = React.useState(false);

    React.useEffect(()=> {
        
        CreateClientRequest.username = loginUser.accountId;

        creditRiskAssessment();

    }, []);

    const creditRiskAssessment = async () => {
        
        setLoading(true);

        const riskRequest = {
            username: loginUser.accountId,
        };
        
        const creditRiskResponse = await openCardCreditRisk(riskRequest, loginUser.jwtToken);

        console.log(creditRiskResponse);

        if(creditRiskResponse){
            CreateClientRequest.approvedPurchaseLimit = creditRiskResponse.principal;
            
        }

        else{
            let scoreFactor = 0.5;

            if(creditScore === "A"){
                scoreFactor = 1;
            }

            CreateClientRequest.approvedPurchaseLimit = scoreFactor * income;
        }
        
        swal({
            title: t('CreateCreditCard.Congratulations'),
            text: t('CreateCreditCard.preApproved'),
            icon: "success",
            button: "ok",
        }).then(() => {
            
            setCardApproved(true);
            
        });
        setLoading(false);
        
    }

    const[agreements, setAgreements] = React.useState(false);
    const[agreementAcept, setAgreementAcept] = React.useState(false);
    const[noteAcept, setNoteAcept] = React.useState(false);
    const[aceptEnabled, setAceptEnabled] = React.useState(true);

    const openAgreements = () => {
        setAgreements(true);
    };

    const closeAgreements = () => {
        setAgreements(false);
    };

    const agreementCheck = (e) => {
        console.log(e.target.checked);
        setAgreementAcept(e.target.checked);
    };

    const noteCheck = (e) => {
        console.log(e.target.checked);
        setNoteAcept(e.target.checked);
    };

    React.useEffect(()=> {
        if(agreementAcept===true && noteAcept===true){
            setAceptEnabled(false);
        }
        else{
            setAceptEnabled(true);
        }
    }, [agreementAcept,noteAcept])

    const CreateCard = async () => {

        closeAgreements();

        setLoading(true);
        const productResponse = await createOpenCardProduct(CreateClientRequest, loginUser.jwtToken);
        console.log(productResponse);
        setLoading(false);

        if(!productResponse){
            swal({
                title: t('CreateCreditCard.errorcardCreation'),
                icon: "error",
                button: "ok",
            });
            return;
        };

        swal({
            title: t('CreateCreditCard.Congratulations'),
            text: t('CreateCreditCard.cardCreate'),
            icon: "success",
            button: "ok",
        }).then(()=> {
            history.push("/creditCard/mount");
        }); 

    };

    const rejectCard = () => {
        swal({
            title: t('CreateCreditCard.declined'),
            icon: "info",
            button: "ok",
        }).then(()=> {
            history.push("/creditCard/mount");
            // history.push("/creditCard/qr-payment");
        });
    }
    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", backgroundColor: "#ededed"}}>
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Box
                    sx={{
                        display: "flex",
                        // flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        width: "95%",
                        height: "90%",
                        textAlign: "center",
                        marginTop: {xs: "1.3rem", sm: 0},
                    }}
                >
                {cardApproved ?  
                    <Card sx={{maxWidth: "400px", minWidth: {xs: "80%", sm: "90%", md: "300px", borderRadius: "5%"}}}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{width: "90%", display: "flex", justifyContent: "center"}}>
                                <CardMedia
                                    component="img"
                                    // image={CardImg}
                                    image="/images/creditcards/card_clean.png"
                                    alt="Card"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{width: "90%", display: "flex", justifyContent: "center", textAlign: "center", marginTop: "1.5rem", marginBottom: "1rem"}}>
                                <Typography variant="h4" sx={{color: "black"}}>    
                                    {`$ ${new Intl.NumberFormat('es-CO').format(CreateClientRequest.approvedPurchaseLimit)}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="body1" sx={{color: "black",}}>    
                                    {`${t("CreateCreditCard.rate")}: ${new Intl.NumberFormat('es-CO').format(interestRate * 100)} % ${t("CreateCreditCard.apr")}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                <Grid container sx={{display: "flex", justifyContent: "center", width: {xs: "90%", sm: "90%", md: "80%", }}}>
                                    <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center", marginBottom: "2rem"}}>
                                        <Button onClick={rejectCard} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                            {t('CreateCreditCard.decline')}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center", marginBottom: "2rem"}}>
                                        <Button onClick={openAgreements} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                            {t('CreateCreditCard.accept')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                    :<></>
                }
                <Dialog open={agreements} onClose={closeAgreements}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", marginTop: "1rem", marginLeft: "3rem"}}>
                            <Box
                                component="img"
                                sx={{
                                    height: 35,
                                    width: 210,
                                }}
                                alt="Logo"
                                src="/images/creditcards/logo-opencard.png"
                            />
                        </Grid>
                    </Grid>
                    <DialogContent>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={3} sm={3} md={3} style={{textAlign: "center"}}>
                                <FormControlLabel
                                    control={<Checkbox color="secondary" name="agreement" value="yes" />}
                                    // label="Acepto la autorización de tratamiento de datos"
                                    onChange={agreementCheck}
                                />
                            </Grid>
                            <Grid item xs={9} sm={9} md={9} sx={{display: "flex", alignItems: "center"}}>
                                <Link
                                    variant="body1"
                                    href={`${wupoServerUri.devnet}/Bancolombia-agreement.pdf`}
                                    target='_blank' rel='noopener noreferrer'
                                    sx={{cursor: "pointer"}}
                                >
                                    {t("CreateCreditCard.agreement")}
                                </Link>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} style={{textAlign: "center"}}>
                                <FormControlLabel
                                    control={<Checkbox color="secondary" name="promissoryNote" value="yes" />}
                                    // label="Acepto la autorización de tratamiento de datos"
                                    onChange={noteCheck}
                                />
                            </Grid>
                            <Grid item xs={9} sm={9} md={9} sx={{display: "flex", alignItems: "center"}}>
                                <Link 
                                    variant="body1"
                                    href={`${wupoServerUri.devnet}/Bancolombia-pagare.pdf`}
                                    target='_blank' rel='noopener noreferrer'
                                    sx={{cursor: "pointer"}}
                                >
                                    {t("CreateCreditCard.note")}
                                </Link>
                            </Grid>  
                        </Grid>                  
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeAgreements} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            {t("CreateCreditCard.back")}
                        </Button>
                        <Button disabled={aceptEnabled} onClick={CreateCard} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            {t("CreateCreditCard.Accept")}
                        </Button>
                    </DialogActions>
                </Dialog>

                </Box>
            }
        </Box>
    )
}
