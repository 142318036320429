import React from 'react';
import {fetchSingleClientsInformation} from "../../utilityFunctions/FetchUtil.js";
import {getBankAccount} from "../../utilityFunctions/MarketPlaceUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors"; 
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BsFillPersonFill } from "react-icons/bs";
import { AiOutlineIdcard } from "react-icons/ai";
import { FaMobileAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { FaPiggyBank } from "react-icons/fa";
import AppButton from "../../components/ui/AppButton";
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import AvatarJorge from "../../img/avatars/avatar_jorge.png";
import Box from '@mui/material/Box';
import swal from "sweetalert";
import PersonIcon from '@mui/icons-material/Person';
import { useHistory } from "react-router-dom";


const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const useStyles = makeStyles((theme) => ({

    gridInputs:{
        maxWidth: "600px",
        justifyContent: "center",
        alignItems: "center",
        marginTop:"0.2em",
        marginBottom: "0.2em"
    },
    gridData:{
        maxWidth: "600px",
        // justifyContent: "left",
        // alignItems: "right",
        marginTop:"0.2em",
        marginBottom: "0.2em",
        paddingLeft: "8em"
    },
}));

const dateOptions = {
    // weekday: "short",
    day: "2-digit",
    month: "numeric",
    year: "numeric",
    // hour: "2-digit",
    // minute: "2-digit",
}

let role;

const bankAcountInfo = false;

export default function ClientRol(props) {

    const {loginUser, loadProductsAndSetting} = props

    const classes = useStyles();
    const history = useHistory();

    const[loading, setLoading] = React.useState(false);
    const[profile, setProfile] = React.useState('');

    React.useEffect(() => {
        setLoading(true);
        handleRole();
        getPii();

    }, []);

    const getActiveBankAccount = async () => {
        const bankAccountRequest = {
            username: loginUser.accountId,
        };
        
        const bankAccount = await getBankAccount(bankAccountRequest, loginUser.jwtToken);

        return bankAccount;
    }

    const getPii = async () => {

        const bankAccount = await getActiveBankAccount();
        console.log(bankAccount);

        const clientInfo = await fetchSingleClientsInformation(loginUser.accountId, loginUser.jwtToken);

            setProfile(
                <>
                
                <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={12} sm={3} md={3} sx={{display: "flex", justifyContent: {xs: "center", sm: "start"}, alignItems: "center"}}> 
                            <Grid container direction="column" spacing={4}>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", width: "100%", justifyContent: {xs: "center", sm: "center"}}}>
                                    <Avatar alt="Perfil" src={Avatar} style={{ height: '130px', width: '130px' }}>
                                        <PersonIcon style={{fontSize: "3rem"}} />
                                    </Avatar>
                                </Grid>
                                {/* <Grid item xs={12} sm={12} md={12} sx={{display: {xs: "none", sm: "flex"}, justifyContent: "center"}}>
                                    <Button variant="contained" style={{backgroundColor: Colors.primary, color:"white", marginTop: "1.5rem"}} onClick={clickBack}>
                                        NUEVA
                                    </Button>
                                </Grid> */}
                            </Grid>                       
                        </Grid>
                        <Grid item xs={12} sm={9} md={9} sx={{textAlign: {xs: "center", sm: "start"}}}> 
                            <Typography sx={{typography:{xs: "h5", sm: "h4"}}}>
                                {`${clientInfo.name} ${clientInfo.lastName}`}
                            </Typography>
                            <Grid container direction="row" spacing={4} style={{justifyContent: "start", marginTop: "1.1rem"}}> 
                                <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                    <AiOutlineIdcard size={20} />
                                </Grid>
                                <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                    <Typography variant="body1" color="text.secondary">
                                        {`${clientInfo.idNumber}`}
                                    </Typography>   
                                </Grid>

                                <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                    <FaMobileAlt size={20} />
                                </Grid>
                                <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                    <Typography variant="body1" color="text.secondary">
                                        {`${clientInfo.phone}`}
                                    </Typography>   
                                </Grid>

                                <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                    <HiOutlineMail size={20} />
                                </Grid>
                                <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                    <Typography variant="body2" color="text.secondary">
                                        {`${clientInfo.email}`}
                                    </Typography>   
                                </Grid>
                                {bankAcountInfo === true &&
                                    <>
                                    {!bankAccount ? null:
                                    
                                    bankAccount === "pending" ?
                                    <>
                                        <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                            <FaPiggyBank size={20} />
                                        </Grid>
                                        <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                            <Button onClick={pendingApproval} sx={{backgroundColor: "none", color: "GrayText", fontSize: "0.8rem", fontWeight: "bold"}}>
                                                Cuenta en aprobación
                                            </Button>
                                        </Grid>
                                    </>
                                    : bankAccount === "not found" ?
                                        <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                            <Button onClick={pendingRegister} variant="contained" sx={{backgroundColor: Colors.primary, color:"white", marginTop: "1.5rem"}}>
                                                Inscribe tu cuenta
                                            </Button>
                                        </Grid>
                                    :
                                    <>
                                        <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                            <FaPiggyBank size={20} />
                                        </Grid>
                                        <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                            <Typography variant="body2" color="text.secondary">
                                                {`${bankAccount.bankAccountTypeString} - ${bankAccount.bankAccount}`}
                                            </Typography>   
                                        </Grid>
                                    </>
                                    }
                                    </>   
                                }       
                                
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Box sx={{display: {xs: "flex", sm: "none"}, width: "100%", justifyContent: {xs: "center", sm: "start"}}}>
                        <Button variant="contained" sx={{display: {xs: "none", sm: "none", md: "flex"}, backgroundColor: Colors.primary, color:"white", marginTop: "1.5rem"}} onClick={clickBack}>
                            NUEVA
                        </Button>
                    </Box> */}
                </Box>
                </>
            );
            setLoading(false);
        

    };

    const pendingApproval = () => {
        swal({
            title: "Pendiente Aprobación",
            text: "Tu cuenta todavía no está activa. Estamos revisando la documentación para activarla",
            icon: "info",
            button: "Entiendo",
        });
    }

    const pendingRegister = () => {
        history.push({pathname: "/info/bank-account"});
    }

    const handleRole = () => {
        return(new Promise((resolve) => {
            if(loginUser.roles && !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)){
                role = loginUser.roles[0].authority;
                console.log(role);
                resolve(role);
            }
        }));
    };

    const handleClick = () =>{
        setLoading(true);

        if(role && role === "ROOT"){
            history.push({pathname: "/root-profile"});
            setLoading(false);
            return;
        }

        else if(role && role === "OPERATOR"){
            history.push({pathname: "/operator-role"});
            setLoading(false);
            return;
        }

        else{
            loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then((loadedInfo) => {
                if(loadedInfo.visitorLoans){
                    history.push({pathname: "/credit/handle_credit"});
                    setLoading(false);
                }

                else{
                    history.push({pathname: "/account/create_credit"});
                    setLoading(false);
                }  
            });
        }
    };

    const clickBack = () =>{
        // history.push({pathname: "/account/handle_account"})
        history.push({pathname: "/info/bank-account"})
    }

    return (
        <div
        style={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
          height: "100%",
          textAlign: "center"
        }}
        >
            {loading ? <PuffLoader size={200} color={Colors.secondary} css={cssLoader} /> :
               profile
            }
        </div>
    )   
}
