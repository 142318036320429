import React from 'react';
import { Box, Card, CardMedia, Grid, Typography, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import Colors from "../components/ui/Colors"
import {getWalletAccount} from '../utilityFunctions/WalletUtil';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useHistory } from "react-router-dom";

const assetsImg = [
    {
        asset: "USD",
        file: "/images/Currencies/usdt-token.png",
        label: "US Dollar",
    },
    {
        asset: "EUR",
        file: "/images/Currencies/euro-token.png",
        label: "Euro",
    },
    {
        asset: "COP",
        file: "/images/Currencies/cop-token.png",
        label: "Colombian Peso",
    },
    {
        asset: "GHS",
        file: "/images/Currencies/ghana-token.png",
        label: "Ghana Cedi",
    },
];

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
};

export default function WalletAccountStatement(props) {

    const { loginUser } = props;

    const history = useHistory();

    React.useEffect(() => {
        initialValues();
    }, []);

    const[account, setAccount] = React.useState(null);

    const initialValues = async () => {

        const sourceAccount = props?.location?.state?.sourceAccount;
        console.log(sourceAccount);
        if(!sourceAccount){ 
            return null; 
        }

        const walletAccountRequest = {
            id: sourceAccount.id,
        };

        const walletAccount = await getWalletAccount(walletAccountRequest, loginUser.jwtToken);

        console.log(walletAccount);
        
        if(!walletAccount){
            return;
        };

        setAccount(walletAccount);
        
        return walletAccount;
    }

    const toDetail = (transaction) => {
    };

    const toHandleWallet = () => {
        history.push({pathname: "/wallet/acounts"});
    }
    
    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", backgroundColor: "#ededed",}}>
            <Box
                    sx={{
                        display: "flex",
                        // flex: 1,
                        justifyContent: "center",
                        alignItems: {xs: "start", sm: "center", md: "center"},
                        width: "100%",
                        height: "100%",
                        textAlign: "center",
                        overflowY: "scroll",
                        
                    }}
                >
                {account &&
                    <Card sx={{display: "flex", justifyContent: "center", maxWidth: "500px", width:{xs: "95%", sm: "90%", md: "60%"}, borderRadius: "15px", minWidth: {xs: "95%", sm: "90%", md: "300px"}, marginTop: {xs: "3rem", sm: "1rem", md: "0rem"}}}>
                        <Grid container sx={{width: "90%"}}>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "0.2rem", marginBottom: "0.2rem"}}>
                                <Grid container>
                                    <Grid item xs={2} sm={2} md={2} sx={{width: "85%", display: "flex", justifyContent: "center", marginTop: "0.2rem", marginBottom: "0.2rem"}}>
                                        <CardMedia
                                            sx={{width: "3rem", height: "3rem", borderRadius: "50%", marginLeft: "0.5rem"}}
                                            component="img"
                                            image={assetsImg.filter((asset) => asset.asset === account.asset)[0].file}
                                            alt="Currency symbol"
                                            />
                                    </Grid>
                                    <Grid item xs={10} sm={10} md={10} sx={{textAlign: "end", display: "flex", alignItems: "center", justifyContent: "end"}}>
                                        <Typography variant="body1" sx={{color: "GrayText"}}>
                                            {/* {account.asset === "GHS" ? "CEDI" : `${account.asset}`} */}
                                            {assetsImg.filter((asset) => asset.asset === account.asset)[0].label}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>  
                                        <Grid container direction="row"
                                            sx={{display: "flex", justifyContent: "center", marginTop: "0.2rem", marginBottom: "0.2rem", textAlign:"left", marginRight: "0.6em"}}>
                                            <Grid item xs={12} sm={12} md={12} >
                                            <Typography  variant="h6" sx={{fontWeight: "bold"}} >
                                                {`${new Intl.NumberFormat('en-US').format(account.balance)}`}
                                            </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Typography sx={{fontSize: "0.75rem", color: "#808080"}}>
                                                    {`${new Intl.NumberFormat('en-US').format(props?.location?.state?.sourceAccount.usdBalance)} USD`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "0.2rem", marginBottom: "0.2rem"}}>
                                <Box sx={{ display: "flex", justifyContent: "center", width: "100%", maxHeight: {xs: "300px", sm: "300px"}, overflowY: "auto" }}>
                                    <Grid container>
                                    {account.statementWalletAccountTransactions && account.statementWalletAccountTransactions.map((transaction) => {
                                        return(
                                            <>
                                                <Grid item xs={12} sm={12} md={12} sx={{marginBottom: "0.8rem"}}>
                                                    <Divider/>
                                                </Grid>
                                                <Grid key={generateRandomKey()}  item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "start", textAlign: "start", marginBottom: "0.2rem"}}>
                                                    <Typography key={generateRandomKey()}  variant="body2" sx={{color: "gray", fontSize: "0.7rem"}}>{`${new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(transaction.date))}`}</Typography>
                                                </Grid>
                                                {transaction.transactionType === "DEBIT" ?
                                                <Grid key={generateRandomKey()}  item xs={5} sm={5} md={5} lg={5} onClick={()=>toDetail(transaction)} sx={{display: "flex", justifyContent: "start", textAlign: "start", cursor: "pointer"}}>
                                                    <Typography variant="body2" sx={{color: Colors.primary}}>{`$ ${new Intl.NumberFormat('en-US').format(transaction.amount)}`}</Typography>
                                                </Grid>
                                                :
                                                <Grid key={generateRandomKey()}  item xs={5} sm={5} md={5} lg={5} onClick={()=>toDetail(transaction)} sx={{display: "flex", justifyContent: "start", textAlign: "start", cursor: "pointer"}}>
                                                    <Typography variant="body2">{`$ ${new Intl.NumberFormat('en-US').format(transaction.amount)}`}</Typography>
                                                </Grid>
                                                }
                                                <Grid key={generateRandomKey()}  item xs={6} sm={6} md={6} lg={6} onClick={()=>toDetail(transaction)} sx={{display: "flex", justifyContent: "center", textAlign: "center", cursor: "pointer"}}>
                                                    <Typography variant="body2">{transaction.destinationWalletAccountForStatementDao.name ? transaction.destinationWalletAccountForStatementDao.name  : transaction.destinationWalletAccountForStatementDao.username}</Typography>
                                                </Grid>
                                                <Grid key={generateRandomKey()}  item xs={1} sm={1} md={1} lg={1} onClick={()=>toDetail(transaction)} sx={{display: "flex", justifyContent: "center", textAlign: "center", cursor: "pointer"}}>
                                                    <NavigateNextIcon sx={{color: "#03a9f4"}}/>
                                                </Grid>
                                                <Grid key={generateRandomKey()}  item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "start", textAlign: "start", marginTop: 0, marginBottom: "1rem"}}>
                                                    <Typography key={generateRandomKey()}  variant="body2" sx={{color: "gray", fontSize: "0.7rem"}}>{`($ ${new Intl.NumberFormat('en-US').format(transaction.rate * transaction.amount)} ${assetsImg.filter((asset) => asset.asset === transaction.destinationAsset)[0].label})`}</Typography>
                                                </Grid>
                                            </>
                                        )
                                    })
                                    }
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                <Button onClick={toHandleWallet} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "1.5rem", marginBottom: "1.5rem"}}>
                                    Back
                                </Button>
                            </Grid>

                        </Grid>  
                    </Card>
                }
                </Box>
        </Box>

    )
}
