import React, { useState, useEffect } from "react";
import { useHistory, Link} from "react-router-dom";
import { PuffLoader } from "react-spinners";
import swal from "sweetalert";
import { css } from "@emotion/react";
import ContentForm from "../../layout/ContentForm";
import StepProgress from "../../components/ui/StepProgress";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";
import Colors from "../../components/ui/Colors";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
// import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import discAuthSecondFactorTextlaimerText from '../../Texts/Security';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {getUxLanguage} from "../../utilityFunctions/AuthUtil";
import { fetchDevicesUsers } from "../../../src/utilityFunctions/FetchUtil";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {updateExplanations} from "../../../src/utilityFunctions/AdminUtil";
import {loginExplanation} from "../../Texts/Explanations";
import { b2bClient } from "../../Api";
import {authCondition, operationUnsuccesful, authConditionEmail} from "../../Texts/OperationResult";
import { useTranslation } from 'react-i18next';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const userNewDevice = {
      
  username: "",
  password: "",
  totpToken: "",
  deviceConfirmation: "CONFIRMATION_PENDING",
  deviceFingerprint: "",
  totpEnabled: false
};

let secondFactorCallTrigger = false;

const fpPromise = FingerprintJS.load();
// const fpPromise = FingerprintJS.load({token: 'nDQ31LvAqr3zFsWM8omJ'});

let profileCompletedCondition = false;

const useStyles = makeStyles((theme) => ({
  
  dialogStyle:{
    whiteSpace: "pre-line"
  },

  enableButton:{
    backgroundColor: "red",
  },
  
  autocomplete: {
    width: "16em",
  },

}));

 const loginData = {
        accountId: "",
        ilpBearerToken: "",
        totpToken: "",
        jwtToken: "",
        profileCompleted: "",
        roles: "",
 };

let autocompleteUsers = false; 

let usersAutocompleteArray;

let isRootOrOperator = false;

const openCard = true;

export default function Login(props) {
  const { userdata, wupoServerUri, updateNavBarEnabled, updateDisplayExplanations,
    loadProductsAndSetting, 
    navBarEnabled} = props;
  const history = useHistory();

  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const steps = [
    {
      key: "username",
      name: t("Register.email"),
      value: "",
    },
    {
      key: "password",
      name: t("Register.password"),
      value: "",
    },
    {
      key: "token",
      name: "TOKEN",
      value: "",
    },
  ];

  const [userInfo, setUserInfo] = useState({
    username: "",
    password: "",
    token: "",
  });
  const [stepNumber, setStepNumber] = useState(0);
  const [inputText, setInputText] = useState("");
  const [inputType, setInputType] = useState("text");
  const [length, setLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [authFactor, setAuthFactor] = useState(false);

  useEffect(() => {
    setInputText("");
    if(steps[stepNumber].key === "password") {
      setInputType("password")
    } else if(steps[stepNumber].key === "token") {
      setInputType("number")
      setLength(6)
    } else {
      setInputType("text")
    }
  }, [stepNumber]);

  const [usersAssignedToDevice, setUsersAssignedToDevice] = useState(
    {
    options: [{user: "Sin usuario", pwd: ""}],
    getOptionLabel: (option) => option.user,
    }
  );
  
  const deviceFingerPrint = async () =>{
    
    setLoading(true);
    if(props.location.state && props.location.state.autocompleteInfo){
      userNewDevice.deviceFingerprint = props.location.state.autocompleteInfo.deviceFingerprint;
      autocompleteUsers = props.location.state.autocompleteInfo.autocompleteUsers;
      usersAutocompleteArray = props.location.state.autocompleteInfo.usersAutocompleteArray;
      console.log(userNewDevice.deviceFingerprint)
      setLoading(false);
      setUsersAssignedToDevice(
        {
          options: usersAutocompleteArray,
          getOptionLabel: (option) => option.user,
        }
      )
      
    }
    else{
      const fp = await fpPromise
      const result = await fp.get()

      // This is the visitor identifier:
      const visitorId = result.visitorId
      userNewDevice.deviceFingerprint = visitorId;
      console.log(userNewDevice.deviceFingerprint) // DEBUG PRINTING

      const FPVerification = {
        visitorsFingerprint: visitorId
      };
      fetchDevicesUsers(FPVerification).then((deviceUsers) => {
        
        if(deviceUsers !== "Not found"){
          autocompleteUsers = true;
          usersAutocompleteArray = deviceUsers;
          setLoading(false);
          setUsersAssignedToDevice(
            {
              options: deviceUsers,
              getOptionLabel: (option) => option.user,
            }
          )
          
          // console.log(usersAssignedToDevice);
        }
        else{
          setLoading(false);
        }
      });
    }
  };

  const explanationMessages = () => {
    if(!props.location.state){
      swal({
          title: "Explicación",
          text: loginExplanation,
          icon: "success",
          button: "Entiendo",
      });
    }
  };

  const navBarEnabler = async () => {
    await updateNavBarEnabled(false);
  };

  useEffect(() => {
    fetchUxLang();
    navBarEnabler();
    console.log(navBarEnabled);

    if(b2bClient && b2bClient !== "IMS"){
      explanationMessages();
    }
    
    userNewDevice.totpEnabled = false;
    secondFactorCallTrigger = false;
    autocompleteUsers = false;
    userNewDevice.deviceConfirmation = "CONFIRMATION_PENDING";

    /**visitor device Fingerprint identifier */
    deviceFingerPrint();

    /**Initialize the checkbox with false to only show it in its view */
    setAuthFactor(false);
  }, []);

  const fetchUxLang = async() => {
    const langResponse = await getUxLanguage();

    if(!langResponse){
      return;
    }

    i18n.changeLanguage(langResponse.language);
  };

  const onChange = (e) => {
    setInputText(e.target.value);
    setUserInfo({
      ...userInfo,
      [steps[stepNumber].key]: e.target.value,
    });
  };

  const onChangeAutocomplete = (event, value) => {
    // console.log(value);
    if(!value){
      return;
    }
    setInputText(value.user);
    setUserInfo({
      ...userInfo,
      [steps[stepNumber].key]: value.user,
    });
  };

  const login = async () => {
    // console.log(`Automcomplete ${autocompleteUsers}`)
    console.log(navBarEnabled);
    const user = {
      username: userInfo.username,
      password: userInfo.password,
      totpToken: userInfo.token.substring(0, length),
    };
    userNewDevice.username = user.username; 
    userNewDevice.totpToken = user.totpToken;

    if(autocompleteUsers === false){
      userNewDevice.password = user.password;
    } else{
      // autocompleteUsers = false; //UNDER REVIEW
    }
    // console.log(userNewDevice); //LOANS DEBUG PRINTING

    const response = await fetch(`${wupoServerUri.devnet}/api/auth/login`, {
      method: "POST",
      // body: JSON.stringify(user),
      body: JSON.stringify(userNewDevice),
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((error) => {
      setLoading(false);
      setStepNumber(0);
      setLength(0);
      swal({
        title: t("Login."),
        // text: operationUnsuccesful,
        icon: "error",
        button: "Ok",
      });
      console.log("Error msg: " + error);
      return;
    });
    // console.log(loading);
    console.log(response.status);
    const data = await response.json();
  
    // console.log(data); //DEBBUG
    if(data.action){
      loginScenarios(data);
      return;
    }

    else if (data.accessToken && data.verified === true) {
      console.log(navBarEnabled);
      loginData.accountId = data.username;
      loginData.ilpBearerToken = data.ilpBearerToken;
      loginData.totpToken = user.totpToken;
      loginData.jwtToken = data.accessToken;
      loginData.profileCompleted = data.profileCompleted;
      loginData.roles = data.roles;


      profileCompletedCondition = data.profileCompleted;
      
      userdata({
        accountId: data.username,
        ilpBearerToken: data.ilpBearerToken,
        totpToken: user.totpToken,
        jwtToken: data.accessToken,
        profileCompleted: data.profileCompleted,
        roles: data.roles
      });

      //Load an array that defines which, if any, explanation messages will be displayed
      // loadExplanations(data.username);

      const profileName = await determineProfile(data)
      if(profileName && profileName !== "ROOT" && profileName !== "OPERATOR"){
        //If the user is not root or operator the initial info is loaded
        const loadedInfo = await loadProductosInfo(data)
        // console.log(loadedInfo);
      
        if(loadedInfo.visitorAccounts && data.profileCompleted){
          console.log(navBarEnabled);
          // console.log("if account")

          await updateNavBarEnabled(true);

          if(openCard === true){
            history.push("/wallet/acounts"); 
            return; 
          }
          history.push("/wallet/acounts");
          
        }

        else if(data.profileCompleted){
          console.log(navBarEnabled);
          
          await updateNavBarEnabled(true);

          if(openCard === true){
            history.push("/wallet/acounts"); 
            return; 
          }
          history.push("/wallet/acounts");

        }else{
          // await updateNavBarEnabled(false); //Disable NavBar


          const loginParams ={
            username: userNewDevice.username,
            password: userNewDevice.password
          };

          history.push({
            pathname: "/account/generalinfo",
            state: {
              loginParams: loginParams
            }
          });
        }

      
      }
      
      
    } 
    else if(data.status === 401){
      setLoading(false);
      swal({
        title: t("Login.incorrectCredentials"),
        // text: t("Login.incorrectCredentials"),
        icon: "error",
        button: "Ok",
      });
      setStepNumber(0);
      setLength(0);
      return;
    } 
    else if(data.status === 422){
      setLoading(false);
      swal({
        title: t("Login.incorrectCredentials"),
        // text: `\n El token dinámico que ingresaste es incorrecto (los 6 dígitos que te salen en Google Authenticator). 
        
        // Por favor vuelve a intentarlo.
        
        // Si sigues teniendo problemas, por favor contáctanos`,
        icon: "error",
        button: "Ok",
      });
      setStepNumber(0);
      setLength(0);
      return;
    } 
    
    else if(data.message === "Your account has been locked due to 3 failed attempts. It will be unlocked after 24 hours."){
      setLoading(false);
      swal({
        title: t("Login.accountBlocked"),
        text: t("Login.accountBlockedText"),
        icon: "error",
        button: "Ok",
      });
      setStepNumber(0);
      setLength(0);
      return;
    }
    else{
      setLoading(false);
      swal({
        title: t("Login.requestError"),
        // text: operationUnsuccesful,
        icon: "error",
        button: "Ok",
      });
      setStepNumber(0);
      setLength(0);
      return
    }
  
  };

  const loginScenarios = (data) => {
    if(data.action === "confirmation"){
      setLoading(false);
      swal({
        title: t("Login.confirmationRequired"),
        text: `\n Confírmanos si este va a ser el dispositivo con el que ingresarás`,
        icon: "success",
        buttons: {
          confirmed: "Confirmo", 
          notConfirmed: "No Confirmo"}
      })
      .then((value)=>{
  
        switch(value){
          case "confirmed":
            // console.log("confirmed") // DEBUG
            userNewDevice.deviceConfirmation = "FIRST_LOGIN_DEVICE_CONFIRMED";
            // console.log(userNewDevice); //DEBBUG
            // loginNewDevice();
            login();
            break;
          case "notConfirmed":
            // console.log("not confirmed") //DEBUG
            userNewDevice.deviceConfirmation = "NOT_CONFIRMED";
            // loginNewDevice();
            login();
            break;
    
          default:
            break;
        }
        
      });

      return;
    }
    else if(data.action === "emailconfirmation"){
      setLoading(false);
      swal({
        title: t("Login.confirmationRequired"),
        text: t("Login.authConditionEmail"),
        icon: "success",
        button: "Confirmado"
      }).then(() =>{
          setLoading(true);
          login();
      });

      return;
    }

    else if(data.action === "email"){
      setLoading(false);
      swal({
        title: t("Login.confirmationRequired"),
        text: t("Login.authConditionEmail"),
        icon: "success",
        button: "Confirmado"
      })
      .then(()=>{
        userNewDevice.deviceConfirmation = "DEVICE_EMAIL_CONFIRMED";
        if(!data.totpEnabled){
          // setLoading(false);
          // console.log("Entering !totpEnabled") //DEBUG
          setLoading(true);
          login();
          // handleSecondFactorBox();
        }
        else{
          swal({
            title: "GRACIAS POR LA CONFIRMACIÓN",
            text: `\n Por favor vuelve a ingresar tu código dinámico. Ya se te venció\n\n`,
            icon: "success",
            button: "Listo"
          }).then(() => {

              setInputText("");
              setStepNumber(2);
              setLength(6);
            
          });
        }
      });

      return;
    }
    else if(data.action === "totp"){
      setLoading(false);
      //If totp is enabled the user must enter the totp
      userNewDevice.totpEnabled = true;
      setAuthFactor(true);
      setInputText("");
      setStepNumber(2);
      setLength(6);
    }
  };

  const loadExplanations = (username) => {
    setLoading(true)
    updateDisplayExplanations(username).then((explanations) => {
      if(explanations && explanations.loginMessage){
        updateExplanations(username, "loginMessage");
      }     
    });
  }

  const loadProductosInfo = async (data) => {

    const loadingResult = await loadProductsAndSetting("/", data.username, data.accessToken);

    return new Promise((solve) => {
      solve(loadingResult);
    });
  };

  const handleNextClick = () => {
    // console.log(inputText)
    if (!inputText) return;
    if (stepNumber < steps.length - 2) {
      /* Autocomplete checkpoint*/
      if(steps[stepNumber].key === "username" && autocompleteUsers === true){
        const autoUserFiltered = usersAutocompleteArray.find((autoUser) => autoUser.user === inputText);
        if(!autoUserFiltered){
          autocompleteUsers = false;
          setStepNumber(stepNumber + 1);
          return;
        }
        // console.log(autoUserFiltered); //LOANS DEBUG PRINTING
        userNewDevice.password = autoUserFiltered.password;
        // console.log(userNewDevice.password); //LOANS DEBUG PRINTING
        setLoading(true);
        login();
      } else{
          setStepNumber(stepNumber + 1);
      }

    } else {
        setLoading(true);
        login();
    }
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    let bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => binary += String.fromCharCode(b));
    return window.btoa(binary);
  }

  //Authentication second factor varibales to open a dialog box and set if enabled or not since the registration
  const [secondFactorBox, setSecondFactorBox] = React.useState(false);

  function handleSecondFactorBox(){
    setSecondFactorBox(true);
  }

  //TODO: Being tested
  const handleEnableSecondFactor= () => {
    // setSecondFactor(true);
    
    swal({
      title: "CONFRIMACIÓN HABILITACIÓN",
      text: `\n Por favor confírmanos que quieres activar el código dinámico`,
      icon: "success",
      buttons: {
        confirmed: "Confirmo", 
        notConfirmed: "Atrás"}
    }).then((value  )=>{
      switch(value){
        case "confirmed":
          userNewDevice.totpEnabled = true;
    
          handleSecondFactorClose();
          setLoading(true);
          enableSecondFactor();
          //Call the QRRegisteer component
          break;
        case "notConfirmed":
          handleSecondFactorBox();
          break;
        default:
          break;
      }
    });

    
  };

  const handleDisableSecondFactor= async () => {
    handleSecondFactorClose();

    userdata({
      ...loginData
    });

    if(profileCompletedCondition){
      updateNavBarEnabled(true);
      history.push("/wallet/acounts");
    }else{
      await updateNavBarEnabled(false);
      history.push("/account/generalinfo");
    }
  };

  const handleSecondFactorClose = () => {
    setSecondFactorBox(false);
   
  };

  const enableSecondFactor = () => {
      // console.log(userNewDevice) // DEBUG
      fetch(`${wupoServerUri.devnet}/api/auth/enabletotp`, {
        method: "POST",
        body: JSON.stringify(userNewDevice),
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) =>{
        console.log(response.status)
        if(response.status === 200){
          setLoading(false);
          return response.arrayBuffer();
        }else if(response.status === 202){
          setLoading(false);
          swal({
            title: "Factor adicional deshabilitado",
            text: `\n Desahabilitaste uno de los factores de autenticación`,
            icon: "success",
            button: "Entendido",
          }).then(() => {
            
            handleDisableSecondFactor(); 
            
          });
        }
      }).then((body) => {
        let imgBase64 = arrayBufferToBase64(body);
        let base64Flag = "data:image/jpeg;base64,"
        const img = base64Flag + imgBase64;

        history.push({
          pathname: "/qrregister",
          state: {
            qrImg: img
          }
        })
      })
      .catch((error) => {
        setLoading(false);
        setStepNumber(0);
        setLength(0);
        swal({
          title: t("Login.requestError"),
          // text: `\n Por favor vuelve a intentarlo.`,
          icon: "error",
          button: "Ok",
        });
        console.log("Error msg: " + error);
      });
  }

  const handleAuthFactor = () => {
     //Move from here
    secondFactorCallTrigger = true;
    // enableSecondFactor();
  }

  const[profile, setProfile] = React.useState('');

  const determineProfile = (loginUser) =>{
    return (new Promise((resolve) => {
      if(loginUser.roles && !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)){
        
        if(loginUser.roles.some((rol) => rol.authority === "ADMIN")){
          setProfile("ADMIN");
          resolve("ADMIN");
          // history.push({pathname: "/account/handle_account"});
        }

        else if(loginUser.roles.some((rol) => rol.authority === "ROOT")){
          isRootOrOperator=true;
          setProfile("ROOT");
          resolve("ROOT")
          history.push({pathname: "/root-profile"});

        }

        else if(loginUser.roles.some((rol) => rol.authority === "OPERATOR")){
          isRootOrOperator=true;
          setProfile("OPERATOR");
          resolve("OPERATOR")
          history.push({pathname: "/operator-role"});
        }

        else{
          setProfile("USER");
          resolve("USER");
        }

      }
    }));
      
  };

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexGrow: "1",
          justifyContent: "center",
        }}
      >
        <ContentForm>
          {loading ? (
            <PuffLoader size={200} color={Colors.secondary} css={cssLoader} />
          ) : (
            <>
              <StepProgress stepNumber={stepNumber} steps={steps} />
              <PageLabel>{steps[stepNumber].name}</PageLabel>
              {steps[stepNumber].key === "username" && autocompleteUsers ? (
                <Autocomplete className={classes.autocomplete}
                  {...usersAssignedToDevice}
                  id="username"
                  clearOnEscape
                  autoComplete = {true}
                  noOptionsText = {t("Login.userNotDevice")}
                  // sx={{ width: 600 }}
                  onChange={onChangeAutocomplete}
                  onInputChange={onChange}
                  renderInput={(params) => (
                    <TextField {...params} label={t("Login.email")} variant="standard" />
                  )}
                />
              ) : steps[stepNumber].key === "password" ? (
                <Box sx={{maxWidth: "300px", width: "100%"}}>
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    variant="standard"
                    value={inputText}
                    onChange={onChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handlePasswordVisibility} edge="end">
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>  
              )
              :
                <Box sx={{maxWidth: "300px", width: "100%"}}>
                  <TextField
                    fullWidth
                    variant="standard"
                    value={inputText}
                    onChange={onChange}
                    // inputProps={{ maxLength: 10, minLength: 10}}
                  />
                </Box> 
              }
              
              <AppButton primary onClick={handleNextClick} width={"40%"}>
                {stepNumber === steps.length - 2 ? "OK" : "OK"}
              </AppButton>
              <Link to="/forgot-password">{t("Login.passwordForgotten")}</Link>
              {authFactor ?
                <>
              <FormControlLabel
                required
                control={<Checkbox color="secondary" name="disableSecondFactor" value="no" />}
                label="Deshabilitar el código dinámico"
                onChange={handleAuthFactor}
              />
              </>
              :<></>}
            </>
          )}
          <React.Fragment>
            <Dialog open={secondFactorBox} onClose={handleSecondFactorClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">MAYOR SEGURIDAD</DialogTitle>
              <DialogContent>
                <DialogContentText className={classes.dialogStyle}>
              
                {discAuthSecondFactorTextlaimerText}

                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button 
                onClick={handleEnableSecondFactor} 
                color="red"
                >
                    Habilitar
                </Button>
                <Button onClick={handleDisableSecondFactor} color="primary">
                    Continuar
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        </ContentForm>
      </div>
    </>
  );
}