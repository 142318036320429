import React, {useEffect} from 'react'
import { useHistory } from "react-router-dom"
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Activity from './Activity';
import ContactLocation from './ContactLocation';
import IDInformation from './IDInformation';
import swal from "sweetalert";
import { Box, useMediaQuery } from "@mui/material";
import { phoneValidation, phoneCode } from "../../../src/utilityFunctions/FetchUtil";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import { isUsernamePhone, b2bClient, isPhoneVerifyEnabled} from "../../Api";
import { useTranslation } from 'react-i18next';
import CreditRiskInformation from './CreditRiskInformation';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

  const onboardingInformation={
    username: "",
    password: "",
    profile: "",
    name:"",
    firstName:"", //OC request
    secondName: "", //OC request
    lastName:"",
    firstLastName:"", //OC request
    secondLastName:"", //OC request
    idType:"",
    idNumber:"",
    issueDate:"", //OC request
    nationality:"",
    birthCountry:"",
    homeCountry:"",
    address:"",
    municipality:"",
    phone:"",
    email:"",
    occupation:"",
    income:"",
    taxes:"",
    amlRisk:"low",
    privacyConsent:"",
    date:"",
    accountId:"",
    clientType: "",
    representativeName: null,
    representativeLastName: null,
    representativeIdType: null,
    representativeIdNumber: null,
    
    companyType: null,
    studyLevel: null,
    housingType: null,
    scoioeconomicStratum: null,
    maritalStatus: null,
    dependents: null,
    jobType: null,
    companyName: null, 
    monthlyIncome: null,
    otherIncome: null,
    totalExpenses: null, 
    totalAssets: null,
    totalLiabilities: null,

    uniqueCode: null,
    mcc: null,
  }

  const verificationObject = {
    code: "",
    phone: "",
    account: "",
    idType:"",
    idNumber:"",
  }

  // const steps = ['Identificación', 'Contacto', 'Finanzas'];


const setMunicipality = (value) => {
  console.log(value)
  onboardingInformation.municipality=value.code;
    
}


const openCard=true;

export default function AMLForm(props) {
  const{ loginUser, wupoServerUri, enableAccountAsset, enablePaymentValue, 
    updateNavBarEnabled, loadProductsAndSetting, userdata } = props
  const history = useHistory();

  const { t } = useTranslation();

  // const steps = [t("AMLForm.multipleStepsNames.firstStep")];
  const steps = [t("AMLForm.multipleStepsNames.firstStep"), t("AMLForm.multipleStepsNames.secondStep")];
  
  const [activeStep, setActiveStep] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const isSmallStepper = useMediaQuery(theme => theme.breakpoints.down('lg'));
  
  const stepperProps = {
    alternativeLabel: isSmallStepper ? true : false,
  }

  useEffect(()=>{
    
    asignInitialLoginValues();

  },[]);

  const asignInitialLoginValues = () => {
    // if(props.location && props.location.state && props.location.state.loginParams){
      onboardingInformation.username=props.location.state.loginParams.username;
      onboardingInformation.password=props.location.state.loginParams.password;
    // }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <IDInformation 
          dataForParent={setDataFromChilds}
          setMunicipality={setMunicipality}
          setIdIssueDate={setIdIssueDate}
          
        />;
      case 1:
        return<CreditRiskInformation
          dataForParent={setDataFromChilds}

        />
      case "x":
        return <ContactLocation 
          dataForParent={setDataFromChilds}
        />;
      case "y":
        return <Activity 
          dataForParent={setDataFromChilds}
        />; 
      default:
        throw new Error('Unknown step');
    }
  }

  const[isMerchant, setIsMerchant] = React.useState(false);
  const[isUser, setIsUSer] = React.useState(false);

  const setDataFromChilds = (event, name) =>{
    
    let propertyName="";
    let propertyValue="";

    if(name){
      propertyName=name;
      propertyValue=event.floatValue;
    }
    else{
      propertyName=event.target.name;
      propertyValue=event.target.value;
    }

    switch (propertyName){
      case "clientType":
        onboardingInformation.clientType=propertyValue;

        // Reset all other properties to null
        Object.keys(onboardingInformation).forEach((key) => {
          console.log(key);
          if (key !== "clientType" && key !== "profile" && key !=="username" && key !== "password") {
            onboardingInformation[key] = null;
          }
        });

        if(event.target.value==="company"){
          onboardingInformation.idType="nit";
          onboardingInformation.secondName=" ";
          onboardingInformation.firstLastName=" ";
          onboardingInformation.secondLastName=" ";
        }

        enableNextButton();
      return;
      // case "name":
      //   onboardingInformation.name=event.target.value;
      //   enableNextButton();
      // return;
      case "firstName":
        onboardingInformation.firstName=propertyValue;
        enableNextButton();
      return;
      case "secondName":
        onboardingInformation.secondName=propertyValue;
        enableNextButton();
      return;
      // case "lastName":
      //   onboardingInformation.lastName=event.target.value;
      //   enableNextButton();
      // return;
      case "firstLastName":
        onboardingInformation.firstLastName=propertyValue;
        enableNextButton();
      return;
      case "secondLastName":
        onboardingInformation.secondLastName=propertyValue;
        enableNextButton();
      return;
      case "idType":
        onboardingInformation.idType=propertyValue;
        enableNextButton();
      return;
      case "idNumber":
        onboardingInformation.idNumber=propertyValue.replace(/[\s+.+,+]/g, '');
        enableNextButton();
      return;
      case "privacyConsent":
        onboardingInformation.privacyConsent=event.target.checked;
        onboardingInformation.date=new Date();
        enableNextButton();
      return;
      case "nationality":
        onboardingInformation.nationality=propertyValue;
        enableNextButton();
      return;
      case "birthCountry":
        onboardingInformation.birthCountry=propertyValue;
        enableNextButton();
      return;
      case "homeCountry":
        onboardingInformation.homeCountry=propertyValue;
        enableNextButton();
      return;
      case "Address":
        onboardingInformation.address=propertyValue;
        enableNextButton();
      return;
      case "phone":
        onboardingInformation.phone=propertyValue;
        enableNextButton();
      return;
      case "email":
        onboardingInformation.email=propertyValue;
        enableNextButton();
      return;
      case "occupation":
        onboardingInformation.occupation=propertyValue;
        if(propertyValue!=="lowRisk1" && propertyValue!=="lowRisk2"){
          onboardingInformation.amlRisk="high";
        }
        enableNextButton();
      return;
      case "income":
        onboardingInformation.income=propertyValue;
        enableNextButton();
      return;
      case "tax":
        onboardingInformation.taxes=propertyValue;
        enableNextButton();
      return;
      // case "merchantCode":
      //   accountObject.retailCashBackCode=event.target.value;
      //   enableNextButton();
      // return;
      case "representativeName":
        onboardingInformation.representativeName=propertyValue;
        enableNextButton();
      return;
      case "representativeLastName":
        onboardingInformation.representativeLastName=propertyValue;
        enableNextButton();
      return;
      case "representativeIdType":
        onboardingInformation.representativeIdType=propertyValue;
        enableNextButton();
      return;
      case "representativeIdNumber":
        onboardingInformation.representativeIdNumber=propertyValue;
        enableNextButton();
      return;
      case "profile":
        onboardingInformation.profile=propertyValue;
        if(propertyValue === "merchant"){
          setIsMerchant(true);
          setIsUSer(false);
        }else{
          setIsMerchant(false);
          setIsUSer(true);
        }
        enableNextButton();
      return;
      //OC request
      case "companyType":
        onboardingInformation.companyType=propertyValue;
        enableNextButton();
      return;
      case "studyLevel":
        onboardingInformation.studyLevel=propertyValue;
        enableNextButton();
      return;
      case "residenceType":
        onboardingInformation.housingType=propertyValue;
        enableNextButton();
      return;
      case "scoioeconomicStratum":
        onboardingInformation.scoioeconomicStratum=propertyValue;
        enableNextButton();
      return;
      case "maritialStatus":
        onboardingInformation.maritalStatus=propertyValue;
        enableNextButton();
      return;
      case "dependents":
        onboardingInformation.dependents=propertyValue;
        enableNextButton();
      return;
      case "employmentType":
        onboardingInformation.jobType=propertyValue;
        enableNextButton();
      return;
      case "companyName":
        onboardingInformation.companyName=propertyValue;
        enableNextButton();
      return;
      case "monthlyIncome":
        onboardingInformation.monthlyIncome=propertyValue;
        enableNextButton();
      return;
      case "otherIncome":
        onboardingInformation.otherIncome=propertyValue;
        enableNextButton();
      return;
      case "totalExpenses":
        onboardingInformation.totalExpenses=propertyValue;
        enableNextButton();
      return;
      case "totalAssets":
        onboardingInformation.totalAssets=propertyValue;
        enableNextButton();
      return;
      case "totalLiabilities":
        onboardingInformation.totalLiabilities=propertyValue;
        enableNextButton();
      return;
      case "uniqueCode":
        onboardingInformation.uniqueCode=propertyValue;
        enableNextButton();
      return;
      case "mcc":
        onboardingInformation.mcc=propertyValue;
        enableNextButton();
      return;

      default:
        console.log("unknown name :" + event.target.name);
    }

    
  }

  const setIdIssueDate = (date) => {
    onboardingInformation.issueDate=date;
    enableNextButton();
  };

  const enableNextButton = () => {

    if(onboardingInformation.profile==="merchant" && activeStep === 1){
      if(onboardingInformation.studyLevel && onboardingInformation.housingType &&
        (onboardingInformation.scoioeconomicStratum || onboardingInformation.scoioeconomicStratum ===0) && onboardingInformation.maritalStatus
        && (onboardingInformation.dependents || onboardingInformation.dependents===0) && onboardingInformation.jobType &&
        onboardingInformation.companyName && (onboardingInformation.monthlyIncome || onboardingInformation.monthlyIncome===0)&&
        (onboardingInformation.otherIncome || onboardingInformation.otherIncome===0)&& (onboardingInformation.totalExpenses || onboardingInformation.totalExpenses===0) &&
        (onboardingInformation.totalAssets || onboardingInformation.totalAssets===0) && (onboardingInformation.totalLiabilities || onboardingInformation.totalLiabilities===0)){
          console.log("All completed");
          setEnableNext(false);
          return;
      }
      else{
        console.log("All incomplete");
        console.log(onboardingInformation);
        setEnableNext(true);
        return;
      }

    }
    else{
      if(onboardingInformation.clientType === "company"){
        console.log("entering company")
        if(onboardingInformation.firstName && onboardingInformation.secondName&& onboardingInformation.firstLastName && 
          onboardingInformation.secondLastName &&
          onboardingInformation.idType && onboardingInformation.idNumber && onboardingInformation.address
          && onboardingInformation.municipality && onboardingInformation.representativeName && 
          onboardingInformation.representativeLastName && onboardingInformation.representativeIdType &&
          onboardingInformation.representativeIdNumber && onboardingInformation.phone){
            console.log("complete company")
            setEnableNext(false);
            return;
        }
      }

      else if(onboardingInformation.clientType === "person"){
        console.log("entering person")
        if(onboardingInformation.firstName && onboardingInformation.firstLastName && onboardingInformation.issueDate &&
          onboardingInformation.idType && onboardingInformation.idNumber && onboardingInformation.address
          && onboardingInformation.municipality && onboardingInformation.phone){
            console.log("complete person")
            console.log(onboardingInformation.phone)
            setEnableNext(false);
            return;
        }
      }
      
      console.log("incomplete")
      console.log(onboardingInformation);
      setEnableNext(true);
      return;
    }
    
  }

  const[enableNext, setEnableNext] = React.useState(true);


  const handleNext = () => {

    if(isUser===true && activeStep===0){
      setEnableNext(true);
      setActiveStep(activeStep + 1);
      return;
    }
    
    if(onboardingInformation.clientType === "company"){
      if(activeStep === 0 && (!onboardingInformation.firstName || 
        !onboardingInformation.idType || !onboardingInformation.idNumber || !onboardingInformation.address
        || !onboardingInformation.municipality || !onboardingInformation.representativeName ||
        !onboardingInformation.representativeLastName || !onboardingInformation.representativeIdType ||
        !onboardingInformation.representativeIdNumber || !onboardingInformation.phone)){
          swal({
            title: t("AMLForm.missingFields"),
            text: t("AMLForm.missingFieldsText"),
            icon: "info",
            button: "Ok",
          });
          return;
      }
    }

    // if(activeStep === 0 && (!onboardingInformation.name || !onboardingInformation.lastName || 
    //   !onboardingInformation.idType || !onboardingInformation.idNumber || !onboardingInformation.address
    //   || !onboardingInformation.municipality || !onboardingInformation.phone)){
    //     swal({
    //       title: "DATOS REQUERIDOS",
    //       text: `\n Debes completar todos los campos \n \n
    //             `,
    //       icon: "info",
    //       button: "Ok",
    //     });
    //     return;         
    // }
    
    onboardingInformation.accountId=loginUser.accountId;

    // onboardingInformation.phone=loginUser.accountId;
    //Determine if an OTP will be send to the user's phone and verify it against external databases
    if(isPhoneVerifyEnabled === true){
      console.log("Verification phone enabledç++++++")
      phoneDialogOpen(); //UNCOMMENT
    }
    else{
      handleFinish();
    }
  };

  const handleBack = () => {

    setActiveStep(activeStep - 1);

    Object.keys(onboardingInformation).forEach((key) => {
      if (key !=="username" && key !== "password") {
        onboardingInformation[key] = null;
      }
    });
  };

  const handleFinish = () => {
    
    console.log(onboardingInformation)
    fetch(`${wupoServerUri.devnet}/api/secure/clientspost`, {      
      method: 'POST',
      body: JSON.stringify(onboardingInformation),
      headers: {
        'Authorization': `Bearer ${loginUser.jwtToken}`, 
        'Content-Type': 'application/json; charset=utf-8'                     
      }
      
    }).then((response)=> { 
      console.log(response.status);
      return response.json();
  
    }).then((body)=>{
        console.log(body);
        userdata({
          accountId: body.username,
          ilpBearerToken: body.ilpBearerToken,
          totpToken: body.totpToken,
          jwtToken: body.accessToken,
          profileCompleted: body.profileCompleted,
          roles: body.roles 
        });
        
        setLoading(false);

        if(b2bClient && b2bClient !== "IMS"){
            updateNavBarEnabled(true)
            setLoading(true);
            loadProductsAndSetting("/account/create_credit", loginUser.accountId, loginUser.jwtToken).then((loadedInfo) => {
              if(loadedInfo.visitorLoans){
                history.push({pathname: "/credit/handle_credit"});
                setLoading(false);
              }

              else if(props.location.state && props.location.state.creditInformation){
                history.push({pathname: "/account/create_credit",
                        state:{
                          creditInformation: props.location.state.creditInformation
                        }
                }); 
              }
        
              else{
                //YC change
                  history.push({pathname: "/account/create_credit"});
                  // history.push({pathname: "/account/handle_account"});
                setLoading(false);
              }
                
            });
          
        }else{
          console.log("Client === IMS");
          updateNavBarEnabled(true)
            setLoading(true);
            loadProductsAndSetting("/account/create_credit", loginUser.accountId, loginUser.jwtToken).then((loadedInfo) => {
              if(loadedInfo.visitorLoans){
                if(openCard === true){
                  history.push("/wallet/acounts"); 
                  return; 
                }
                history.push("/selling/marketplace");
                setLoading(false);
              }

              else if(props.location.state && props.location.state.creditInformation){
                if(openCard === true){
                  history.push("/wallet/acounts"); 
                  return; 
                }
                 history.push("/selling/marketplace");
              }
        
              else{
                //YC change
                if(openCard === true){
                  history.push("/wallet/acounts"); 
                  return; 
                }
                history.push("/selling/marketplace");
                  // history.push({pathname: "/account/handle_account"});
                setLoading(false);
              }
                
            });
        }
    }).catch((error)=>{
        console.log("Error msg: "+ error);
    });
  };

  const mobileVerification = () => {
 
    setLoading(true);

    if(isUsernamePhone){
      verificationObject.phone = loginUser.accountId;
    }else{
      verificationObject.phone = onboardingInformation.phone;
    }
    verificationObject.code = verificationCode;
    verificationObject.account = loginUser.accountId;
    verificationObject.idType = onboardingInformation.idType;
    verificationObject.idNumber = onboardingInformation.idNumber;


    phoneValidation(verificationObject, loginUser.jwtToken).then((verificationRes) =>{
      
      if(!verificationRes.result || verificationRes.result === "denied" || verificationRes.result !== "approved"){

        if(verificationRes.reason === "WRONGCODE"){
          swal({
            title: "CÓDIGO INVÁLIDO",
            text: `\n El código que ingresaste no es correcto. Por favor vuelve a revisar tus mensajes de texto y busca un código con 4 números.
            `,
            icon: "error",
            button: "Ok"
          });
          
          //TODO: under review, the idea is to leave the text to include the sms
          // phoneDialogClose();
          setLoading(false);
          return;
        }
      
        else if(verificationRes.reason === "WRONGIDVSPHONE"){
          swal({
            title: "DATOS NO COINCIDEN",
            text: `\n Tus datos no coinciden con los consultados. Por favor revisa la información que ingresaste.
            `,
            icon: "error",
            button: "Ok"
          });
          
          phoneDialogClose();
          setLoading(false);
          return;
        }

        else if(verificationRes.reason === "RETRYBLOCKED"){
          swal({
            title: "Límite de intentos superado",
            text: `\n Intentaste más del número de veces aceptado. Por tu seguridad, no permitimos que hagas más intentos. Por favor comunícate con nostros para que podamos resolverlo.
            `,
            icon: "error",
            button: "Ok"
          }).then(() => {
            history.push({pathname: "/login"});
          });
          return;  
        }

        else{
          swal({
            title: "Autenticación inválida",
            icon: "error",
            button: "Ok"
          });

          phoneDialogClose();
          setLoading(false);
          return; 
        }
        
      }else{
        handleFinish();
      }
    });
 
  };

  const phoneDialogClose = () => {
    setPhoneVerification(false);

  };

  const phoneDialogOpen = () => {
    console.log("Entering the phonedialog open")
    verificationObject.account = loginUser.accountId;
    
    if(isUsernamePhone){
      verificationObject.phone = loginUser.accountId;
    }else{
      verificationObject.phone = onboardingInformation.phone;
    }
    setPhoneVerification(true);

    phoneCode(verificationObject, loginUser.jwtToken).then((verificationResult) => {
      if(verificationResult.idVerificationBlocked){
        swal({
          title: "Límite de intentos superado",
          text: `\n Intentaste más del número de veces aceptado. Por tu seguridad, no permitimos que hagas más intentos. Por favor comunícate con nostros para que podamos resolverlo
          `,
          icon: "error",
          button: "Ok"
        }).then(() => {
          history.push({pathname: "/"});
        });
        return;
      }
    });
  }

  const [phoneVerification, setPhoneVerification] = React.useState(false);
  const [verificationCode, setverificationCode] = React.useState("");

    return (
      <Box sx={{display: "flex", justifyContent: "center", alignItems: "center",  width: "100%", height: "100%", backgroundColor: "#ededed",}}>
      {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> 
      : 
      <>
        <CssBaseline />
        <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: "center",
                width: "90%",
                height: "100%",
                textAlign: "center",
            }}
          >
          <Paper sx={{width: {xs: "90%", sm: "95%", md: "60%"}, marginTop: "5%", marginBottom: "5%"}}>
            <Typography variant="h4" align="center" sx={{marginTop: "5%", marginBottom: "2rem"}}>
              Tu Información
            </Typography>
            <Stepper {...stepperProps} activeStep={activeStep} sx={{marginBottom: "2rem"}}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Gracias! 
                  </Typography>
                  {isPhoneVerifyEnabled ? 
                    <Button variant="contained" color="primary" onClick={phoneDialogOpen}>
                      Súper
                    </Button>
                    :
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleFinish}
                    >
                      Súper
                    </Button>
                  }
                  
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <Box sx={{display: "flex", justifyContent: "center"}}>
                    {activeStep !== 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{marginTop: "3rem", marginBottom: "2rem", marginRight: "1rem"}}
                        onClick={handleBack}>
                        Atrás
                      </Button>
                    )}
                    {(isUser && isUser===true) ?
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={enableNext}
                        sx={{marginTop: "3rem", marginBottom: "2rem", marginRight: "0.5rem"}}
                      >
                        {activeStep === steps.length - 1 ? "Ok" : "Siguiente"}
                      </Button>
                      :
                      <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      disabled={enableNext}
                      sx={{marginTop: "3rem", marginBottom: "2rem"}}
                    >
                      {activeStep === steps.length - 1 ? 'Ok' : 'Ok'}
                    </Button>
                    }
                    
                  </Box>
                </React.Fragment>
              )}
            </React.Fragment>
           
          </Paper>
          {/* <Copyright /> */}
        </Box>
        
        <React.Fragment>
        <Dialog open={phoneVerification} onClose={phoneDialogClose} aria-labelledby="form-dialog-tokens">
          <DialogTitle id="form-dialog-title3">VERIFICACIÓN CELULAR</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{maxWidth: "350px", whiteSpace: "pre-line"}}>
                <Typography align="center">
                  Ingresa el código de 4 números que te enviamos como mensaje de texto al celular:
                </Typography>
                <Typography align="center" setEnableNext={{fontWeight: "bolder"}}>
                  {onboardingInformation.phone}
                </Typography>
            </DialogContentText>
            <TextField
                autoFocus
                autoComplete="one-time-code"
                margin="dense"
                id="name"
                label="Ingresa el código"
                type="text"
                fullWidth
                inputProps={{ maxLength: 4, minLength: 4, inputMode: "numeric"}}
                onChange={e=>setverificationCode(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
          <Button onClick={phoneDialogClose} color="primary">
                Atrás
            </Button>
            <Button onClick={mobileVerification} color="primary">
                Listo
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      </>
      }
      </Box>
      
    )
}
